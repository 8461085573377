import { Api } from '@/api.js';

export default {
    name: 'EmailValidation',

    data() {
        return {
            verificationError: {
                state: false,
                message: 'Please verify your email and refresh the page',
                type: 'verification'
            },
            confirmEmail: {
                state: false,
                message: 'Verification email has been sent',
                type: 'notification'
            },
            emailFailed: {
                state: false,
                message: 'Failed to send email',
                type: 'notification'
            },
            sendingEmail: false,
        }
    },

    methods: {
        resendVerification() {
            this.sendingEmail = true;
            Api.get('email/resend')
                .then(() => {
                    this.verificationError.state = false;
                    this.confirmEmail.state = true;
                })
                .catch(() => {
                    this.verificationError.state = false;
                    this.emailFailed.state = true;
                })
                .finally(() => {
                    this.sendingEmail = false;
                });
        },

        popupContentMixin() {
            if (this.verificationError.state === true) {
                return this.verificationError;
            } else if (this.confirmEmail.state === true) {
                return this.confirmEmail;
            } else if (this.emailFailed.state) {
                return this.emailFailed;
            }
        },

        clearErrorsMixin() {
            this.verificationError.state = false;
            this.confirmEmail.state = false;
            this.emailFailed.state = false;
        }
    }
}