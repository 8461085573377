<template>
    <item-info-form
        v-if="editingItem"
        @close-form="toggleForm()"
        :item="selectedItem"
        @save-item="saveItem($event)"
        :savedisabled="isSaving"
        :errors="errors"
    ></item-info-form>
    <message-popup
        v-if="openPopup"
        :content="chooseContent()"
        @close-notification="cleanErrors()"
        @confirm-action="chooseAction(selectedItem)"
        :actionStatus="chooseActionStatus()"
    ></message-popup>
    <profile-card>
        <div class="get-error-message" v-if="error">Something went wrong :(</div>
        <loader 
            v-else-if="loading"
            :loading="loading"
            :finishedLoading="fetchedItems"
        ></loader>
        <div v-else class="my-wishlist-container">
            <div class="wishlist-action-row">
                <a v-if="!editing" class="edit-anchor" @click="editItems">Edit Items</a>
                <span v-else>
                    <a class="wishlist-anchors" @click="addItem">Add New Item</a>
                    <a class="wishlist-anchors" @click="editItems">Done</a>
                </span> 
            </div>
            <div class="items-container">
                <wishlist-row
                    v-for="item in items"
                    :item="item"
                    :key="item.id"
                    wishlist="me"
                    :user="user"
                    :editing="editing"
                    @delete-item="deletePopUp($event)"
                    @edit-item="editItem($event)"
                ></wishlist-row>
            </div>
        </div>
    </profile-card>
</template>

<script type="text/javascript">
    import ProfileCard from '../layout-components/ProfileCard.vue';
    import WishlistRow from '../reusable-components/WishlistRow.vue';
    import ItemInfoForm from '../popups/ItemInfoForm.vue';
    import EmailValidation from '@/mixins/notvalidated.js';
    import { Api } from '@/api.js';
    import { Validator } from '../../classes/validator.js';
    import Loader from '../layout-components/Loader.vue';
    
    export default {
        name: 'MyWishlistPage',

        components: {
            ProfileCard,
            WishlistRow,
            ItemInfoForm,
            Loader
        },

        mixins: [EmailValidation],

        data() {
            return {
                user: JSON.parse(localStorage.getItem("authUser")),
                currentOrganization: localStorage.getItem('current_organization'),
                items: null,
                editing: false,
                selectedItem: null,
                editingItem: false,
                addingItem: false,
                loading: false,
                fetchedItems: false,
                deletingItem: {
                    state: false,
                    message: 'Are you sure you want to delete this item?',
                    type: 'confirm-message'
                },
                deletingAction: false,
                error: false,
                deleteError: {
                    state: false,
                    message: 'Failed to delete item :(',
                    type: 'notification'
                },
                editError: {
                    state: false,
                    message: 'Failed to edit item :(',
                    type: 'notification'
                },
                addError: {
                    state: false,
                    message: 'Failed to add item :(',
                    type: 'notification'
                },
                isSaving: false,
                rules: {
                    name: "required",
                    link: "url",
                    price: "max:100000000|number"
                },
                errors: {},
            }
        },

        computed: {
            openPopup() {
                return this.verificationError.state || this.confirmEmail.state || this.emailFailed.state || this.deleteError.state || this.editError.state || this.addError.state || this.deletingItem.state ? true : false;
            }
        },

        created() {
            this.loading = true;
            Api.get(`organization/${this.currentOrganization}/user/${this.user.id}/items`)
                .then(response => {
                    this.items = response.data
                    this.fetchedItems = true;
                })
                .catch((error) => {
                    this.error = true;
                    if (error.response.status === 403) {
                        this.verificationError.state = true;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        methods: {
            editItems() {
                this.editing = !this.editing;
            },

            stopEditing() {
                this.editing = false;
            },

            deletePopUp(itemToDelete) {
                this.deletingItem.state = true;
                this.selectedItem = itemToDelete;
            },

            async deleteItem(itemToDelete) {
                this.deletingAction = true;
                try {
                    await Api.delete(`item/${itemToDelete.id}`);
                    this.items = this.items.filter(item => item.id !== itemToDelete.id);
                } catch {
                    this.deleteError.state = true;
                } finally {
                    this.deletingAction = false;
                    this.deletingItem.state = false;
                    this.selectedItem = null;
                }
            },

            cancelDelete() {
                this.selectedItem = null;
                this.deletingItem.state = false;
            },

            toggleForm() {
                this.editingItem = !this.editingItem;
                this.errors = {};
            },

            editItem(item) {
                this.selectedItem = item;
                this.addingItem = false;
                this.toggleForm();
            },

            addItem() {
                this.selectedItem = {};
                this.addingItem = true;
                this.toggleForm();
            },

            async saveItem(item) {
                const {success, errors} = Validator.validate(this.selectedItem, this.rules);
                if (success) {
                    this.isSaving = true;
                    if (this.addingItem) {
                        try {
                            const response = await Api.post(`organization/${this.currentOrganization}/item`, {
                                name: item.name,
                                link: item.link,
                                price: item.price,
                                color: item.color,
                                user_id: this.user.id
                            });
                            this.toggleForm();
                            this.items.push(response.data);
                            this.addingItem = false;
                            this.editing = false;
                        } catch(error) {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                            } else {
                                this.addError.state = true;
                                this.toggleForm();
                            }                        
                        }
                    } else {
                        try {
                            const response = await Api.put(`item/${item.id}`, {
                                name: item.name,
                                link: item.link,
                                price: item.price,
                                color: item.color,
                                user_id: this.user.id
                            });
                            for (let counter = 0; counter < this.items.length; counter++) {
                                if (response.data.id === this.items[counter].id) {
                                    this.items[counter] = response.data;
                                    break;
                                }
                            }
                            this.toggleForm();
                            this.isSaving = false;
                            this.editing = false;
                        } catch(error) {
                             if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                            } else {
                                this.editError.state = true;
                                this.toggleForm();
                            }
                        }
                    }
                } else {
                    this.errors = errors;
                }
                this.isSaving = false;
            },

            cleanErrors() {
                this.addError.state = false;
                this.editError.state = false;
                this.deleteError.state = false;
                if (this.deletingItem.state === true) {
                    this.selectedItem = null;
                    this.deletingItem.state = false;
                }
                this.clearErrorsMixin();
            },

            chooseContent() {
                if(this.addError.state === true) {
                    return this.addError;
                } else if (this.editError.state === true) {
                    return this.editError;
                } else if (this.deleteError.state === true) {
                    return this.deleteError;
                } else if (this.deletingItem.state === true) {
                    return this.deletingItem;
                } else {
                    return this.popupContentMixin();
                }
            },

            chooseAction(selectedItem) {
                if (this.deletingItem.state === true) {
                    return this.deleteItem(selectedItem);
                } else {
                    return this.resendVerification();
                }
            },

            chooseActionStatus() {
                if (this.deletingItem.state === true) {
                    return this.deletingAction;
                } else if (this.verificationError.state === true) {
                    return this.sendingEmail;
                }
            }
        }
    }
</script>

<style type="text/css">
    .my-wishlist-container {
        padding: 22px 0;
        height: 491px;
        width: 100%;
        border: 1px solid var(--lightgrey);
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    .items-container {
        height: 405px;
        width: 100%;
        padding: 10px 8.75%;
        overflow-x: hidden;
        overflow-y: overlay;
    }
    .wishlist-action-row {
        height: 60px;
        width: 90%;
        text-align: right;
    }
    .edit-anchor,
    .wishlist-anchors {
        color: var(--darkest);
        font-size: 16px;
        text-decoration: underline;
        text-underline-offset: 4px;
    }
    .edit-anchor {
        text-decoration-thickness: 2px;
        font-weight: 600;      
    }
    .wishlist-anchors {
        text-decoration-thickness: 1px;
    }
    .wishlist-anchors:nth-child(1) {
        margin-right: 6px;
    }
    @media screen and (max-width: 530px) {
        .my-wishlist-container {
            padding: 10px 2%;
        }
    }
    @media screen and (max-height: 700px) {
        .my-wishlist-container {
            height: 429px;
        }
        .items-container {
            height: 343px;
        }
    }
</style>
