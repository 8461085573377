<template>
    <main-card class="main-card-in-profile">
        <div class="profile-header-row">
            <div class="profile-header-right">
                <router-link to="/profile" class="nav-buttons profile">
                    Profile
                </router-link>
                <router-link to="/profile/mywishlist" class="nav-buttons my-wishlist">
                    My Wishlist
                </router-link>
            </div>
        </div>
        <slot/>
    </main-card>
</template>

<script>
    import MainCard from './MainCard';

    export default {
        name: 'ProfileCard',

        components: {
            MainCard
        },
    }
</script>

<style scoped>
    .profile-header-row {
        background-color: var(--darker);
        height: 51px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .profile-header-right {
        margin-right: 90px;
    }
    .my-wishlist {
        margin-left: 10px;
    }
    .profile {
        margin-right: 10px;
    }
    @media screen and (max-width: 530px) {
        .profile-header-row {
            justify-content: center;
        }
        .profile-header-right {
            margin-right: 0;
        }
    }
</style>