import App from './App.vue';
import { createApp } from 'vue';
import { router } from './routes/router.js';
import axios from 'axios';
import { clickOutside } from './directives/clickoutside.js';
import { focus } from './directives/focus.js';
import MessagePopup from './components/popups/MessagePopup.vue';
import MainCard from './components/layout-components/MainCard.vue';
import XIcon from './components/svgs/XIcon.vue';
import './assets/styles/main-styles.css';

createApp(App)
	.use(router, axios)
	.component('message-popup', MessagePopup)
	.component('main-card', MainCard)
	.component('x-icon', XIcon)
	.directive('click-outside', clickOutside)
	.directive('focus', focus)
	.mount('#app');
