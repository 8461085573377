<template>
    <div class="pages-background">
        <div class="top-left">
            <balloon-one></balloon-one>
            <balloon-two></balloon-two>
            <balloon-three></balloon-three>
        </div>
        <div class="top-right">
            <balloon-three></balloon-three>
            <balloon-two></balloon-two>
            <balloon-one></balloon-one>
        </div>
    </div>
</template>

<script type="text/javascript">
    import BalloonOne from '../svgs/BalloonOne';
    import BalloonTwo from '../svgs/BalloonTwo';
    import BalloonThree from '../svgs/BalloonThree';

    export default {
        name: 'PagesBackground',

        components: {
            BalloonOne,
            BalloonTwo,
            BalloonThree
        }
    }
</script>

<style type="text/css" scoped>
    .pages-background {
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0);
        height: 100%;
        width: 100%;
        z-index: -3;
        display: flex;
        justify-content: space-between;
        padding-top: 64px;
    }
    .top-left {
        min-width: 320px;
    }
    @media screen and (max-width: 700px) {
        .pages-background {
            justify-content: center;
        }
        .top-right{
            display: none;
        }
    }
</style>