<template>
    <svg width="167" height="122" viewBox="0 0 167 122" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.5">
            <path opacity="0.5" d="M30.1056 31.0851L30.1056 32.0621L24.5693 32.0621L24.5693 31.0851L30.1056 31.0851Z" class="svg-paths-3"/>
            <path opacity="0.5" d="M26.849 28.8056L27.826 28.8056L27.826 34.3419L26.849 34.3419L26.849 28.8056Z" class="svg-paths-3"/>
        </g>
        <g opacity="0.5">
            <path opacity="0.5" d="M18.0963 83.6034L18.0963 84.5804L12.56 84.5804L12.56 83.6034L18.0963 83.6034Z" class="svg-paths-3"/>
            <path opacity="0.5" d="M14.8397 81.3268L15.8167 81.3268L15.8167 86.8632L14.8397 86.8632L14.8397 81.3268Z" class="svg-paths-3"/>
        </g>
        <g opacity="0.5">
            <path opacity="0.5" d="M118.493 80.8986L118.493 81.8756L112.956 81.8756L112.956 80.8986L118.493 80.8986Z" class="svg-paths-3"/>
            <path opacity="0.5" d="M115.236 78.6189L116.213 78.6189L116.213 84.1552L115.236 84.1552L115.236 78.6189Z" class="svg-paths-3"/>
        </g>
        <g opacity="0.5">
            <path opacity="0.5" d="M42.814 11.5965L42.814 12.5735L37.2776 12.5735L37.2776 11.5965L42.814 11.5965Z" class="svg-paths-3"/>
            <path opacity="0.5" d="M39.5574 9.31966L40.5344 9.31966L40.5344 14.856L39.5574 14.856L39.5574 9.31966Z" class="svg-paths-3"/>
        </g>
        <path opacity="0.5" d="M37.8534 107.304C37.9709 107.573 38.1831 107.79 38.4498 107.912C38.7165 108.035 39.019 108.056 39.2998 107.97C39.3427 107.956 39.387 107.947 39.4319 107.944C39.565 107.924 39.7009 107.95 39.8167 108.019C39.9325 108.088 40.0213 108.194 40.068 108.32C40.1148 108.446 40.1168 108.585 40.0736 108.712C40.0304 108.84 39.9447 108.948 39.8309 109.02C39.7945 109.047 39.7551 109.07 39.7135 109.088C39.4445 109.205 39.2279 109.417 39.1051 109.684C38.9823 109.951 38.9618 110.253 39.0475 110.534C39.0614 110.577 39.0702 110.621 39.0739 110.666C39.0936 110.799 39.067 110.935 38.9984 111.051C38.9299 111.167 38.8236 111.256 38.6974 111.302C38.5712 111.349 38.4327 111.351 38.3052 111.308C38.1778 111.265 38.069 111.179 37.9972 111.065C37.9702 111.029 37.9476 110.989 37.9297 110.948C37.8122 110.679 37.6 110.462 37.3333 110.339C37.0666 110.217 36.7641 110.196 36.4833 110.282C36.4404 110.296 36.3961 110.305 36.3512 110.308C36.2181 110.328 36.0822 110.301 35.9664 110.233C35.8506 110.164 35.7618 110.058 35.7151 109.932C35.6683 109.806 35.6663 109.667 35.7095 109.54C35.7527 109.412 35.8384 109.303 35.9522 109.232C35.9886 109.205 36.028 109.182 36.0696 109.164C36.3387 109.047 36.5552 108.834 36.678 108.568C36.8008 108.301 36.8213 107.998 36.7356 107.718C36.7217 107.675 36.7129 107.63 36.7092 107.586C36.6895 107.452 36.7161 107.317 36.7847 107.201C36.8532 107.085 36.9595 106.996 37.0857 106.949C37.212 106.903 37.3504 106.901 37.4779 106.944C37.6054 106.987 37.7141 107.073 37.7859 107.187C37.8129 107.223 37.8355 107.262 37.8534 107.304Z" class="svg-paths-3"/>
        <path opacity="0.5" d="M45.3862 41.908C45.5037 42.177 45.7159 42.3936 45.9826 42.5164C46.2493 42.6392 46.5518 42.6597 46.8326 42.574C46.8755 42.5601 46.9198 42.5513 46.9646 42.5476C47.0978 42.5279 47.2337 42.5545 47.3495 42.6231C47.4653 42.6916 47.5541 42.7979 47.6008 42.9241C47.6476 43.0503 47.6496 43.1888 47.6064 43.3163C47.5632 43.4438 47.4775 43.5525 47.3637 43.6243C47.3273 43.6513 47.2879 43.6739 47.2463 43.6918C46.9773 43.8087 46.7606 44.0203 46.6373 44.2863C46.514 44.5524 46.4927 44.8545 46.5774 45.1353C46.5912 45.1781 46.6001 45.2225 46.6038 45.2673C46.6235 45.4005 46.5968 45.5364 46.5283 45.6522C46.4597 45.768 46.3534 45.8567 46.2272 45.9035C46.101 45.9503 45.9626 45.9522 45.8351 45.9091C45.7076 45.8659 45.5988 45.7802 45.527 45.6663C45.5001 45.63 45.4774 45.5906 45.4595 45.549C45.3421 45.2799 45.1298 45.0634 44.8631 44.9406C44.5964 44.8178 44.2939 44.7973 44.0131 44.883C43.9703 44.8968 43.926 44.9057 43.8811 44.9094C43.7479 44.9291 43.6121 44.9024 43.4962 44.8339C43.3804 44.7653 43.2917 44.659 43.2449 44.5328C43.1981 44.4066 43.1962 44.2682 43.2394 44.1407C43.2825 44.0132 43.3682 43.9044 43.4821 43.8326C43.5185 43.8057 43.5578 43.783 43.5994 43.7652C43.8685 43.6477 44.085 43.4354 44.2078 43.1687C44.3306 42.902 44.3511 42.5995 44.2654 42.3187C44.2516 42.2759 44.2427 42.2316 44.239 42.1867C44.2193 42.0536 44.246 41.9177 44.3145 41.8019C44.3831 41.686 44.4894 41.5973 44.6156 41.5505C44.7418 41.5038 44.8802 41.5018 45.0077 41.545C45.1352 41.5881 45.244 41.6738 45.3158 41.7877C45.3439 41.8248 45.3676 41.8652 45.3862 41.908Z" class="svg-paths-3"/>
        <path opacity="0.5" d="M136.137 32.5904C136.255 32.8594 136.467 33.076 136.734 33.1988C137.001 33.3215 137.303 33.342 137.584 33.2564C137.627 33.2425 137.671 33.2337 137.716 33.23C137.849 33.2103 137.985 33.2369 138.101 33.3055C138.217 33.374 138.305 33.4803 138.352 33.6065C138.399 33.7327 138.401 33.8712 138.358 33.9987C138.314 34.1261 138.229 34.2349 138.115 34.3067C138.078 34.3337 138.039 34.3563 137.998 34.3742C137.728 34.4917 137.512 34.7039 137.389 34.9706C137.266 35.2373 137.246 35.5398 137.332 35.8206C137.345 35.8635 137.354 35.9078 137.358 35.9526C137.378 36.0858 137.351 36.2217 137.282 36.3375C137.214 36.4533 137.108 36.5421 136.981 36.5888C136.855 36.6356 136.717 36.6376 136.589 36.5944C136.462 36.5512 136.353 36.4655 136.281 36.3517C136.254 36.3153 136.232 36.2759 136.214 36.2343C136.096 35.9652 135.884 35.7487 135.617 35.6259C135.351 35.5031 135.048 35.4826 134.767 35.5683C134.724 35.5821 134.68 35.591 134.635 35.5947C134.502 35.6144 134.366 35.5878 134.25 35.5192C134.135 35.4506 134.046 35.3444 133.999 35.2181C133.952 35.0919 133.95 34.9535 133.994 34.826C134.037 34.6985 134.122 34.5898 134.236 34.5179C134.273 34.491 134.312 34.4684 134.354 34.4505C134.623 34.333 134.839 34.1208 134.962 33.8541C135.085 33.5874 135.105 33.2849 135.02 33.004C135.006 32.9612 134.997 32.9169 134.993 32.872C134.973 32.7389 135 32.603 135.069 32.4872C135.137 32.3713 135.244 32.2826 135.37 32.2358C135.496 32.1891 135.634 32.1871 135.762 32.2303C135.889 32.2735 135.998 32.3592 136.07 32.473C136.097 32.5094 136.12 32.5488 136.137 32.5904Z" class="svg-paths-3"/>
        <path opacity="0.5" d="M98.7738 55.8397C98.8912 56.1088 99.1035 56.3253 99.3702 56.4481C99.6369 56.5709 99.9394 56.5914 100.22 56.5057C100.263 56.4919 100.307 56.483 100.352 56.4793C100.485 56.4596 100.621 56.4863 100.737 56.5548C100.853 56.6234 100.942 56.7297 100.988 56.8559C101.035 56.9821 101.037 57.1205 100.994 57.248C100.951 57.3755 100.865 57.4843 100.751 57.5561C100.715 57.583 100.675 57.6057 100.634 57.6236C100.365 57.741 100.148 57.9533 100.025 58.22C99.9027 58.4867 99.8822 58.7892 99.9679 59.07C99.9817 59.1128 99.9906 59.1571 99.9943 59.202C100.014 59.3352 99.9873 59.471 99.9188 59.5869C99.8502 59.7027 99.7439 59.7914 99.6177 59.8382C99.4915 59.885 99.3531 59.8869 99.2256 59.8437C99.0981 59.8006 98.9893 59.7149 98.9175 59.601C98.8906 59.5646 98.8679 59.5253 98.85 59.4837C98.7326 59.2146 98.5203 58.9981 98.2536 58.8753C97.9869 58.7525 97.6844 58.732 97.4036 58.8177C97.3608 58.8315 97.3165 58.8404 97.2716 58.8441C97.1385 58.8638 97.0026 58.8371 96.8867 58.7686C96.7709 58.7 96.6822 58.5937 96.6354 58.4675C96.5886 58.3413 96.5867 58.2029 96.6299 58.0754C96.673 57.9479 96.7587 57.8391 96.8726 57.7673C96.909 57.7404 96.9483 57.7177 96.9899 57.6998C97.259 57.5824 97.4755 57.3701 97.5983 57.1034C97.7211 56.8367 97.7416 56.5342 97.6559 56.2534C97.6421 56.2106 97.6332 56.1663 97.6295 56.1214C97.6098 55.9882 97.6365 55.8524 97.705 55.7365C97.7736 55.6207 97.8799 55.532 98.0061 55.4852C98.1323 55.4384 98.2708 55.4365 98.3982 55.4797C98.5257 55.5228 98.6345 55.6085 98.7063 55.7224C98.7332 55.7587 98.7559 55.7981 98.7738 55.8397Z" class="svg-paths-3"/>
        <path opacity="0.5" d="M102.479 7.74293C102.597 8.01201 102.809 8.22852 103.076 8.35132C103.342 8.47411 103.645 8.49461 103.926 8.40893C103.969 8.39509 104.013 8.38624 104.058 8.38253C104.191 8.36282 104.327 8.38947 104.443 8.45803C104.558 8.52659 104.647 8.63288 104.694 8.75909C104.741 8.88529 104.743 9.02374 104.7 9.15122C104.656 9.2787 104.571 9.38747 104.457 9.45928C104.42 9.48623 104.381 9.50886 104.339 9.52675C104.07 9.64422 103.854 9.85649 103.731 10.1232C103.608 10.3899 103.588 10.6924 103.673 10.9732C103.687 11.016 103.696 11.0603 103.7 11.1052C103.72 11.2384 103.693 11.3742 103.624 11.4901C103.556 11.6059 103.45 11.6946 103.323 11.7414C103.197 11.7882 103.059 11.7901 102.931 11.7469C102.804 11.7038 102.695 11.6181 102.623 11.5042C102.596 11.4678 102.574 11.4285 102.556 11.3869C102.438 11.1178 102.226 10.9013 101.959 10.7785C101.693 10.6557 101.39 10.6352 101.109 10.7209C101.066 10.7347 101.022 10.7436 100.977 10.7473C100.844 10.767 100.708 10.7403 100.592 10.6718C100.477 10.6032 100.388 10.4969 100.341 10.3707C100.294 10.2445 100.292 10.1061 100.335 9.97857C100.379 9.85109 100.464 9.74232 100.578 9.67051C100.615 9.64357 100.654 9.62093 100.696 9.60304C100.965 9.48557 101.181 9.27332 101.304 9.00664C101.427 8.73995 101.447 8.43743 101.362 8.15661C101.348 8.11376 101.339 8.06946 101.335 8.02458C101.315 7.89144 101.342 7.75556 101.411 7.63973C101.479 7.52391 101.585 7.43517 101.712 7.38841C101.838 7.34164 101.976 7.33968 102.104 7.38285C102.231 7.42603 102.34 7.51173 102.412 7.62557C102.439 7.66195 102.461 7.70134 102.479 7.74293Z" class="svg-paths-3"/>
        <path opacity="0.5" d="M73.6769 55.4788C73.6769 54.3996 74.5517 53.5248 75.6309 53.5248C76.7101 53.5248 77.5849 54.3996 77.5849 55.4788C77.5849 56.5579 76.7101 57.4328 75.6309 57.4328C74.5517 57.4328 73.6769 56.5579 73.6769 55.4788Z" class="svg-paths-3"/>
        <path opacity="0.5" d="M58.2122 24.6198C58.2122 23.4045 59.1974 22.4194 60.4126 22.4194C61.6279 22.4194 62.6131 23.4045 62.6131 24.6198C62.6131 25.8351 61.6279 26.8203 60.4126 26.8203C59.1974 26.8203 58.2122 25.8351 58.2122 24.6198Z" class="svg-paths-3"/>
        <path opacity="0.5" d="M58.2122 24.6198C58.2122 23.4045 59.1974 22.4194 60.4126 22.4194C61.6279 22.4194 62.6131 23.4045 62.6131 24.6198C62.6131 25.8351 61.6279 26.8203 60.4126 26.8203C59.1974 26.8203 58.2122 25.8351 58.2122 24.6198Z" class="svg-paths-3"/>
        <path opacity="0.5" d="M162.995 1.95406C162.995 0.874892 163.87 4.54054e-05 164.949 4.54525e-05C166.028 4.54997e-05 166.903 0.874892 166.903 1.95406C166.903 3.03322 166.028 3.90805 164.949 3.90805C163.87 3.90805 162.995 3.03322 162.995 1.95406Z" class="svg-paths-3"/>
        <path opacity="0.5" d="M160.516 69.4355C160.516 68.2202 161.501 67.2351 162.716 67.2351C163.931 67.2351 164.916 68.2202 164.916 69.4355C164.916 70.6508 163.931 71.636 162.716 71.636C161.501 71.636 160.516 70.6508 160.516 69.4355Z" class="svg-paths-3"/>
        <path opacity="0.5" d="M82.291 34.5384C82.291 33.4592 83.1659 32.5844 84.245 32.5844C85.3242 32.5844 86.199 33.4592 86.199 34.5384C86.199 35.6176 85.3242 36.4924 84.245 36.4924C83.1659 36.4924 82.291 35.6176 82.291 34.5384Z" class="svg-paths-3"/>
        <path d="M35.1215 66.6027L35.8604 66.761C36.1747 66.8282 43.601 68.3609 47.3236 64.4343C49.0922 62.5681 50.2556 60.2942 50.6075 58.0346C50.9734 55.6192 50.3729 53.4964 48.9186 52.0548C47.4643 50.6132 45.3696 50.0663 43.0216 50.5148C40.8238 50.9346 38.6376 52.1939 36.869 54.0624C33.1488 57.9891 34.8799 65.5329 34.9549 65.8519L35.1215 66.6027ZM46.0358 53.0526C46.488 53.213 46.9009 53.4716 47.2462 53.8106C48.1281 54.6861 48.48 56.0533 48.2384 57.6629C47.971 59.4259 47.0374 61.2273 45.6089 62.736C43.3875 65.0795 38.9144 64.7437 37.12 64.4967C36.8221 62.6713 36.3529 58.109 38.5743 55.7655C40.0028 54.2591 41.7339 53.2469 43.4509 52.9207C44.3089 52.7315 45.2005 52.777 46.0358 53.0526Z" class="svg-paths-3"/>
        <path d="M36.2193 66.6362L36.3835 65.883C36.4538 65.5616 38.0583 57.989 34.2724 54.1295C32.4709 52.2945 30.266 51.0736 28.0611 50.6922C25.7154 50.2844 23.6208 50.8673 22.1969 52.3353C20.7731 53.8033 20.2031 55.9333 20.6089 58.332C20.9889 60.5843 22.1899 62.8367 23.9914 64.6716C27.7773 68.5311 35.1778 66.8664 35.4851 66.7945L36.2193 66.6362ZM25.0774 53.2684C25.9071 52.9776 26.797 52.9156 27.6576 53.0885C29.3793 53.3859 31.1268 54.367 32.5835 55.8469C34.8447 58.1521 34.46 62.7191 34.1856 64.5517C32.3958 64.83 27.9274 65.2449 25.6685 62.9422C24.2142 61.4598 23.2525 59.6752 22.9546 57.905C22.6848 56.3003 23.0132 54.9259 23.8811 54.0359C24.2214 53.6952 24.6294 53.4334 25.0774 53.2684Z" class="svg-paths-3"/>
        <path class="svg-paths-2 gift-box" d="M4.55769 69.0733H67.4564V121.276H4.55769V69.0733Z" fill="#D66D5C"/>
        <path d="M30.0831 78.3944H41.9333V121.273H30.0831V78.3944Z" class="svg-paths-3"/>
        <path opacity="0.1" d="M4.55769 66.2764H67.4564V79.3276H4.55769V66.2764Z" fill="black"/>
        <path class="svg-paths-2 gift-cover" d="M0.910019 65.3432H71.1013V78.3943H0.910019V65.3432Z" fill="#D66D5C"/>
        <path d="M30.0831 65.3432H41.9333V78.3943H30.0831V65.3432Z" class="svg-paths-3"/>
    </svg>
</template>

<script type="text/javascript">
    export default {
        name: 'GiftIcon'
    }
</script>