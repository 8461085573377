function deCamelize(string) {
    return string
        .replace(/([a-z\d])([A-Z])/g, '$1' + " " + '$2')
        .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + " " + '$2')
        .replace(/\b\w/g, l => l.toUpperCase())
}
function unSnake(string) {
    return string
        .replace(/_/g, " ")
        .replace(/\b\w/g, l => l.toUpperCase())
}
export class Validator {
    static rules = {
        // validator function should always return an array [boolean] or [boolean, string]
        // it should return [boolean, string] if the errorMsg contains different errors for the same validator
        // for example the min validator returns [false, "number"] to indicate that the errorMsg["number"] should be retruned
        "email": {
            validator: (value) => this.email(value),
            errorMsg: (field) => `the ${unSnake(deCamelize(field))} must be a valid email address.`
        },
        "required": {
            validator: (value) => this.required(value),
            errorMsg: (field) => `the ${unSnake(deCamelize(field))} field is required.`
        },
        "min": {
            validator: (value, minValue) => this.min(value, minValue),
            errorMsg: {
                "string": (field, minValue) => `the ${unSnake(deCamelize(field))} must be at least ${minValue} characters`,
                "number": (field, minValue) => `the ${unSnake(deCamelize(field))} must be at least ${minValue}`
            }
        },
        "max": {
            validator: (value, maxValue) => this.max(value, maxValue),
            errorMsg: {
                "string": (field, maxValue) => `the ${unSnake(deCamelize(field))} may not be greater ${maxValue} characters`,
                "number": (field, maxValue) => `the ${unSnake(deCamelize(field))} may not be greater ${maxValue}`
            }
        },
        "contains": {
            validator: (value, element) => this.contains(value, element),
            errorMsg: (field, element) => this.containsErrorMsg(field, element)
        },
        "date": {
            validator: (value, condition) => this.date(value, condition),
            errorMsg: (field, condition) => this.dateError(field, condition)
        },
        "url": {
            validator: (value) => this.url(value),
            errorMsg: (field) => `the ${unSnake(deCamelize(field))} must be a valid url`
        },
        "number": {
            validator: (value) => [isNaN(value) ? false : parseFloat(value)],
            errorMsg: (field) => `the ${unSnake(deCamelize(field))} must be a number`,
        }
    }

    static mutatingRules = ["number"];

    static regExs = {
        contains: {
            special: /^(?=.*[^A-Za-z0-9])(?=.{1,})/,
            capital: /^(?=.*[A-Z])(?=.{1,})/,
            number: /^(?=.*[0-9])(?=.{1,})/,
        },
        email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        date: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
        url: /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/
    }

    /**
     * @param {string} value - the value you would like to check if it is a valid email.
     */
    static email = (value) => {
        const result = this.regExs.email.test(String(value).toLowerCase());
        return [result];
    }

    /**
     * @param {*} value - the value you would like to check if it is not empty null or undefined.
     */
    static required = (value) => {
        if (typeof (value) === 'undefined' || !value) return [false];
        const trimedValue = String(value).trim();
        if (trimedValue === "") return [false];
        return [true];
    }

    /**
     * @param {(string|number)} value - value you would like to check if its greater than a certain number.
     * @param {number} minValue - the value that you are comparing it to.
     */
    static min = (value, minValue) => {
        if (!["string", "number"].includes(typeof (value))) {
            throw new Error("the min function only compares strings or numbers")
        } else {
            const comparableValue = typeof (value) === "string" ? value.length : value;
            const result = comparableValue >= minValue;
            const errorType = typeof (value);
            return [result, errorType];
        }
    }

    /**
     * @param {(string|number)} value - value you would like to check if its less than a certain number.
     * @param {number} maxValue - the value that you are comparing it to.
     */
    static max = (value, maxValue) => {
        if (!["string", "number"].includes(typeof (value))) {
            throw new Error("the max rule only compares strings or numbers");
        } else {
            const comparableValue = typeof (value) === "string" ? value.length : value;
            const result = comparableValue <= maxValue;
            const errorType = typeof (value);
            return [result, errorType];
        }
    }

    /**
     * @param {(string|number)} value - value you would like to check if it contains an element.
     * @param {string} element - the available option are "capital", "special", "number".
     */
    static contains = (value, element) => {
        if (!["string", "number"].includes(typeof (element))) {
            throw new Error("contains rule only compares strings or numbers");
        }
        const regEx = this.regExs["contains"][element];
        if (!regEx) throw new Error(`${element} is not supported for contains`);
        const result = regEx.test(String(value));
        return [result];
    }

    /**
     * @param {string} field - field name to generate customized error.
     * @param {string} element - the available option are "capital", "special", "number".
     */
    static containsErrorMsg = (field, element = "number") => {
        let errorMsg = `the ${unSnake(deCamelize(field))} must contain at least one ${element}`;
        switch (element) {
            case "special":
                errorMsg += " character";
                break;
            case "capital":
                errorMsg += " letter";
                break;
        }
        return errorMsg;
    }

    /**
     * @param {string} date - the date you would like to validate.
     * @param {string} condition - the available option are "future".
     */
    static date = (date, condition = null) => {
        let result = this.regExs.date.test(String(date));
        if (!condition) {
            return [result];
        }
        else {
            date.replace("-", "/").replace(".", "/");
            const [year, month, day] = date.split("-");
            const now = new Date();
            // javascript counts month from 0 to 11 (-_-)
            const dateObj = new Date(year, month - 1, day);
            if (condition === "future") {
                result = dateObj > now;
                return [result];
            } else if (condition === "past") {
                result = dateObj < now;
                return [result];
            }
        }
        throw new Error(`date:${condition} is not registered`);
    }

    /**
     * @param {string} field - field name to generate customized error.
     * @param {string} condition - the available option are "future".
     */
    static dateError = (field, condition = null) => {
        if (condition) {
            if (condition === "future") {
                return `the ${unSnake(deCamelize(field))} must be a date set in the future`;
            } else if (condition === "past") {
                return `the ${unSnake(deCamelize(field))} must be a date set in the past`;
            }
        }
        return `the ${unSnake(deCamelize(field))} must be a valid date`;
    }

    /**
     * @param {string} value - the value you would like to check if it is a valid url.
     */
    static url = (value) => {
        const result = this.regExs.url.test(String(value));
        return [result];
    }

    /**
     * @param {string} fieldName - name of the field you want to validate.
     * @param {(string|number)} value - value of the field you want to validate.
     * @param {string[]|string} rules - array of rules ie: ["required", "min:9", "max:12", "email"].or "required|min:8|max:13|email"
     */
    static validateField = (fieldName, value, rules) => {
        if (Array.isArray(rules) !== true && typeof (rules) !== "string") {
            throw new Error("rules can only be a string or array");
        }
        //if a string is passed for the rules it gets converted to an array
        if (typeof (rules) === "string") {
            rules = rules.split("|")
        }

        //removing any white space and useless stuff (if the trimed rule is an empty string do not add it to the array of rules)
        rules = rules.flatMap(rule => {
            const rulesWithoutSpaces = rule.replace(/\s/g, "");
            return rulesWithoutSpaces === "" ? [] : [rulesWithoutSpaces];
        });

        const fieldErrors = [];
        const [valueNotEmpty] = this.required(value);
        // if value is null or empty and it is not required it will send success
        if (rules.includes("required") === false && valueNotEmpty === false) {
            return { success: true, errors: [] };
        }

        const isMutating = (rule) => this.mutatingRules.includes(rule);
        const isRequired = (rule) => rule === "required";
        // placing mutating rules and required rule first
        rules = rules.sort((rule1, rule2) => {
            if (isMutating(rule1) && isRequired(rule2)) {
                return 1;
            } else if (isMutating(rule2) && isRequired(rule1)) {
                return -1;
            } else if (isMutating(rule2) || isRequired(rule2)) {
                return 1;
            } else {
                return -1;
            }
        })

        for (const i in rules) {

            // "min:8" -> ruleName = "min" condition="8"
            const [ruleName, condition] = rules[i].split(":");
            if (!this.rules[ruleName]) throw new Error("this rule does not exist");

            const { validator, errorMsg } = this.rules[ruleName];
            const [result, errorType] = validator(value, condition);

            if (this.mutatingRules.includes(ruleName) && result) {
                value = result;
            }


            if (result === false) {
                const error = errorType ? errorMsg[errorType](fieldName, condition) : errorMsg(fieldName, condition);
                fieldErrors.push(error);
                // stop validation if the field is required and not available
                if (ruleName === "required") break;
            }
        }
        const success = !(fieldErrors.length > 0);
        return { success: success, errors: fieldErrors };
    }

    /**
     * @param {Object} form - (key:value) are the name and the value of the fields respectively.
     * @param {Object} rule - (key:value) are the name and the rules of the fields respectively.
     */
    static validate(form = {}, rules = {}) {
        const allErrors = {};
        let allSuccess = true;
        for (const field in rules) {
            const { success, errors } = this.validateField(field, form[field], rules[field]);
            if (!success) {
                allErrors[field] = errors;
                allSuccess = false;
            }
        }
        return { success: allSuccess, errors: allErrors };
    }
}


// some tests
// console.log(Validator.validate({name:"yammine"},{name:["required","min:10", "contains:special"]}))
// console.log(Validator.validate({name:""},{name:["min:10", "contains:special"]}))
// console.log(Validator.validate({name:""},{name:["min:10", "contains:special", "required"]}))
// console.log(Validator.validate({name:""},{name:["required", "min:10", "contains:special"]}))
// console.log(Validator.validate({name: null},{name:["required", "min:10", "contains:special", "contains:capital"]}))
// console.log(Validator.validate({name:9},{name:["required", "min:10", "contains:special", "contains:capital"]}))
// console.log(Validator.validateField("name", null,["required", "min:10", "contains:special", "contains:capital"]))
// console.log(Validator.validate({name:"22-10-2020"},{name:["required", "date:future"]}))
// console.log(Validator.validate({name:"22-131234-202"},{name:["required", "date"]}))
// console.log(Validator.validate({name:"22-131234-202"},{name:["url"]}))