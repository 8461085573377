<template>
    <tr class="staff-page-row" :data-id="key">
        <td class="name-cell capitalized">{{ person.first_name }} {{ person.last_name }}</td>
        <td class="dob-cell">{{ formatdate }}</td>
        <td class="gender-cell capitalized">{{ person.gender }}</td>
        <td class="action-cell">
            <div class="action-buttons-container">
                <router-link :to="{name: 'wishlist', params: {id: person.id}}" class="view-wishlist-button">
                    <eye-icon class="eye-icon-svg"></eye-icon>
                </router-link>
                <button class="delete-button" @click="deleteUser(person)" v-if="hasDelete">
                    <minus-icon class="minus-icon-svg"></minus-icon>
                </button>
            </div>
        </td>
    </tr>
</template>

<script>
    import EyeIcon from '../svgs/EyeIcon.vue';
    import MinusIcon from '../svgs/MinusIcon.vue';

    export default {
        name: 'StaffPageRow',

        components: {
            EyeIcon,
            MinusIcon
        },
        
        props: ['person', 'key', 'formatdate', 'hasDelete'],

        data() {
            return {
                deleting: {
                    state: false,
                    message: "Are you sure you want to delete this user?",
                    type: "confirm-message"
                },

                deleteError: {
                    state: false,
                    message: "Couldn't delete user :(",
                    type: "notification"
                },

                deletingUser: false
            }
        },

        methods: {
            deleteUser(user) {
                this.$emit("delete-user", user)
            }
        }
    }
</script>

<style type="text/css">
    .staff-page-row {
        display: table;
        height: 60px;
        width: 90%;
        max-width: 630px;
        color: var(--darkest);
        font-size: 16px;
        text-align: left;
        border-top: 2px solid var(--lightgrey);
        margin: 0 5%;
        table-layout: fixed;
    }
    .staff-page-row:first-of-type {
        border-top: none;
    }
    .name-cell {
        padding-left: 10px;
        width: 35%;
        max-width: 35%;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .dob-cell {
        padding-left: 23px;
        width: 25%;
        min-width: 25%;
    }
    .gender-cell {
        padding-left: 31px;
        width: 25%;
        min-width: 25%;
    }
    .action-cell {
        width: 15%;
        min-width: 15%;
    }
    .action-buttons-container {
        display: flex;
        align-items: stretch;
        justify-content: space-around;
        margin-right: 25px;
    }
    .view-wishlist-button {
        vertical-align: middle;
        padding-top: 7px;
    }
    .delete-button {
        background-color: white;
        border: none;
        outline: none;
        padding-top: 3px;
    }
    .capitalized {
        text-transform: capitalize;
    }
    @media screen and (max-width: 500px) {
        .staff-page-row td {
            font-size: 14px;
        }
        .staff-page-row td:nth-child(1) {
            width: 30%;
            padding-left: 1%;
        }
        .staff-page-row td:nth-child(2) {
            width: 25%;
            padding-left: 3%;
        }
        .staff-page-row td:nth-child(3) {
            width: 25%;
            padding-left: 6%;
        }
        .action-buttons-container {
            margin-right: 8px;
        }
        .view-wishlist-button {
            margin-left: 22%;
            padding-bottom: 4px;
        }
        .eye-icon-svg,
        .minus-icon-svg {
            width: 20px;
            height: 20px;
        }
    }
    @media screen and (max-width: 350px) {
        .staff-page-row td {
            font-size: 13px;
        }
    }
</style>
