<template>
    <div class="item-row" :class="{'is-clickable' : hasMoreInfo}" @click="toggleMoreInfo($event)" v-click-outside="closeMoreInfo">
        <div class="row-top-container">
            <div class="item-name-container">
                <button v-if="editing" class="delete-item" @click="deleteItem(item)">
                    <minus-icon class="my-wishlist-icons"></minus-icon>
                </button>
                <button v-if="editing" class="edit-item" @click="editItem(item)">
                    <pen-icon class="my-wishlist-icons"></pen-icon>
                </button>
                <p class="item-name" >{{ item.name }}</p>
            </div>
            <div class="item-info">
                <button v-if="linkAvailable" class="item-link" @click="openLink(item.link)">
                    <link-icon class="link-icon"></link-icon>
                </button>
                <span v-if="wishlist === 'me'">
                    <span v-if="item.reserved_by" class="item-status">Reserved</span>
                    <span v-else class="item-status">Available</span>
                </span>
                <span v-else>
                    <a v-if="item.reserved_by !== null" @[canUndo]="unreserve(item)" class="item-status">{{ reserved }}</a>
                    <a v-else @[canReserve]="reserve(item)" class="item-status">Reserve</a>
                </span>
            </div>
        </div>
        <div class="row-bottom-container" v-if="showMoreInfo">
            <div>{{ item.price ? parseFloat(item.price).toFixed(2) + " $" : "" }}</div>
            <div>{{ item.color }}</div>
        </div>
    </div>
</template>

<script type="text/javascript">
    import { Api } from '@/api.js';
    import MinusIcon from '../svgs/MinusIcon.vue';
    import PenIcon from '../svgs/PenIcon.vue';
    import LinkIcon from '../svgs/LinkIcon.vue';

    export default {
        name: 'WishlistRow',

        components: {
            MinusIcon,
            PenIcon,
            LinkIcon
        },

        props: ['item', 'wishlist', 'editing', 'user', 'organization'],
        
        data() {
            return {
                completingAction: false,
                currentItem: this.item,
                linkAvailable: this.item.link === undefined || this.item.link === null ? false : true,
                reserved: this.item.reserved_by === this.user.id ? "Undo" : "Reserved",
                hasMoreInfo: false,
                showMoreInfo: false
            }
        },

        computed: {
            canReserve() {
                return this.completingAction === false ? "click" : null;
            },
            canUndo() {
                return this.reserved === "Undo" && this.completingAction === false ? "click" : null;
            }
        },

        mounted() {
            return this.hasMoreInfo = this.item.price || this.item.color ? true : false;
        },

        methods: {
            openLink(link) {
                window.open(link);
            },

            async reserve(item) {
                this.completingAction = true;
                try {
                    const response = await Api.post(`organization/${this.organization}/item/reserve/${item.id}`);
                    this.currentItem.reserved_by = response.data.reserved_by;
                    this.reserved = "Undo";
                } catch {
                    this.$emit('reserve-error');
                }
                this.completingAction = false;
            },

            async unreserve(item) {
                this.completingAction = true;
                try {
                    await Api.post(`organization/${this.organization}/item/unreserve/${item.id}`);
                    this.currentItem.reserved_by = null;
                } catch {
                    this.$emit('reserve-error');
                }
                this.completingAction = false;
            },

            deleteItem(item) {
                this.$emit('delete-item', item);
            },

            editItem(item) {
                this.$emit('edit-item', item);
            },

            toggleMoreInfo(e) {
                let dontToggle = [
                    'item-status',
                    'item-link',
                    'link-icon',
                    'delete-item',
                    'edit-item',
                    'my-wishlist-icons'
                ];
                let exist = dontToggle.find(function(item) {
                    return e.target.classList[0] === item;
                });
                if(typeof exist === "undefined") {
                    this.showMoreInfo = this.hasMoreInfo ? !this.showMoreInfo : false;
                }
            },

            closeMoreInfo() {
                this.showMoreInfo = false;
            }
        }
    }
</script>

<style>
    .item-row {
        width: 100%;
        border-bottom: 1px solid var(--lightgrey);
        font-size: 16px;
        color: var(--darkest);
    }
    .row-top-container,
    .row-bottom-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .row-top-container {
        height: 60px;
        align-items: center;
    }
    .row-bottom-container {
        height: 40px;
        align-items: flex-start;
    }
    .item-name-container {
        font-weight: 500;
        width: 50%;
        height: 60px;
        text-align: left;
        overflow: hidden;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 3px 3px 0 0;
    }
    .item-name {
        display: inline;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0;
    }
    .is-clickable {
        cursor: pointer;
    }
    .item-info {
        float: right;
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
    }
    .item-link {
        background-color: white;
        border: none;
        outline: none;
        height: 24px;
        padding: 3px 0 0 0;
        margin-right: 3px;
    }
    .delete-item,
    .edit-item {
        background-color: white;
        border: none;
        outline: none;
        height: 24px;
        padding: 3px 0 0 0;
        margin: 0 5px;
    }
    .delete-item,
    .edit-item {
        background-color: white;
        border: none;
        outline: none;
    }
    @media screen and (max-width: 530px) {
        .item-row {
            font-size: 14px;
        }
        .link-icon {
            width: 20px;
        }
    }
</style>
