<template>
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="svg-paths" d="M20 5.23999C20.0008 5.10838 19.9756 4.97792 19.9258 4.85608C19.876 4.73424 19.8027 4.62343 19.71 4.52999L15.47 0.289986C15.3766 0.197305 15.2658 0.12398 15.1439 0.074215C15.0221 0.0244504 14.8916 -0.000774696 14.76 -1.38033e-05C14.6284 -0.000774696 14.4979 0.0244504 14.3761 0.074215C14.2543 0.12398 14.1435 0.197305 14.05 0.289986L11.22 3.11999L0.290017 14.05C0.197335 14.1434 0.12401 14.2542 0.0742455 14.3761C0.0244809 14.4979 -0.000744179 14.6284 1.67143e-05 14.76V19C1.67143e-05 19.2652 0.105374 19.5196 0.29291 19.7071C0.480446 19.8946 0.7348 20 1.00002 20H5.24002C5.37994 20.0076 5.51991 19.9857 5.65084 19.9358C5.78176 19.8858 5.90073 19.8089 6.00002 19.71L16.87 8.77999L19.71 5.99999C19.8013 5.90307 19.8757 5.79152 19.93 5.66999C19.9397 5.59028 19.9397 5.5097 19.93 5.42999C19.9347 5.38344 19.9347 5.33654 19.93 5.28999L20 5.23999ZM4.83002 18H2.00002V15.17L11.93 5.23999L14.76 8.06999L4.83002 18ZM16.17 6.65999L13.34 3.82999L14.76 2.41999L17.58 5.23999L16.17 6.65999Z"/>
    </svg>
</template>

<script type="text/javascript">
    export default {
        name: 'PenIcon'
    }
</script>