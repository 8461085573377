<template>
    <div class="nav-bar" v-click-outside="closeResponsiveNav">
        <router-link to="/" class="homepage">
            SBC
        </router-link>
        <span class="nav-links-container"  :class="{'menu-open' : isMenuOpen}" v-click-outside="closeSettings">
            <router-link @click="closeResponsiveNav()" to="/" class="nav-buttons staff-page-nav" :class="{'router-link-active': subIsActive('/wishlist/')}">
                Staff
            </router-link>
            <router-link @click="closeResponsiveNav()" class="nav-buttons profile-page-nav" to="/profile" :class="{'router-link-active': subIsActive('/profile/')}">
                Profile
            </router-link>
            <button class="nav-buttons settings-button" @click="toggleSettings()">
                <img class="settings-icon" src="@/assets/vectors/settings.svg">
            </button>
        </span>
        <button class="hamburger-button" @click="toggleResponsiveNav()">
            <img class="hamburger" src="@/assets/vectors/white-hamburger.svg">
        </button>
        <div class="settings-open" v-if="settingsDropdown">
            <a class="settings-links" @click="toggleChangeOrg()">Change organization</a>
            <a class="settings-links" @click="toggleChangeTheme()">Change theme</a>
            <a class="settings-links" @click="logoutPopup('')">Logout</a>
            <a class="settings-links" @click="logoutPopup('other sessions')">Logout from other sessions</a>
            <a class="settings-links" @click="logoutPopup('all sessions')">Logout from all sessions</a>
        </div>
    </div>
    <message-popup
        v-if="openPopup"
        :content="popupContents()"
        :actionStatus="loggingOut"
        @confirm-action="confirmLogout()"
        @close-notification="clearErrors()"
    ></message-popup>
    <theme-picker-popup
        v-if="changingTheme"
        @close="toggleChangeTheme()"
    ></theme-picker-popup>
    <div class="full-page-cover" v-if="changingOrg" @click.self="toggleChangeOrg()">
        <div class="notification choose-org-div">
            <button class="org-change-exit-button" @click="toggleChangeOrg()">
                <x-icon></x-icon>
            </button>
            <p>Choose your organization:</p>
            <select class="choose-org" @change="makeCurrent($event)" :value="currentOrganization.id">
                <option 
                    class="organization"
                    v-for="organization in organizations"
                    :key="organization.id"
                    :value="organization.id"
                >{{organization.name}}</option>
            </select>
        </div>
    </div>
</template>

<script>
    import { Api } from '@/api.js';
    import ThemePickerPopup from '../popups/ThemePickerPopup.vue';
    import { Themes } from '@/utils/themes';

    export default {
        name: 'NavBar',

        components: {
            ThemePickerPopup
        },

        data() {
            return {
                currentOrganization: null,
                organizations: null,
                isMenuOpen: false,
                logoutOpen: false,
                settingsDropdown: false,
                logoutFrom: '',
                logout: {
                    state: false,
                    message: "",
                    type: "confirm-message"
                },
                logoutError: {
                    state: false,
                    message: "Couldn't logout :(",
                    type: "notification"
                },
                loggingOut: false,
                changingOrg: false,
                changingTheme: false,
            }
        },

        computed: {
            openPopup() {
                return this.logout.state || this.logoutError.state ? true : false;
            }
        },

        mounted() {
            window.addEventListener('resize', this.onResize);
        },

        beforeUnmount() { 
            window.removeEventListener('resize', this.onResize);
        },
        
        methods: {
            subIsActive(input) {
                const paths = Array.isArray(input) ? input: [input];
                return paths.some(path => {
                    return this.$route.path.indexOf(path) === 0;
                });
            },

            toggleResponsiveNav() {
                this.isMenuOpen = !this.isMenuOpen;
                this.settingsDropdown = false;
            },

            closeResponsiveNav() {
                this.isMenuOpen = false;
                this.closeSettings();
            },

            toggleSettings() {
                this.settingsDropdown = !this.settingsDropdown;
            },

            openSettings() {
                this.settingsDropdown = true;
            },

            closeSettings() {
                this.settingsDropdown = false
            },

            toggleChangeTheme() {
                this.changingTheme = !this.changingTheme;
            },

            onResize() {
                if(window.innerWidth > 500) {
                    this.isMenuOpen = false;
                }
                if(window.innerWidth <= 500) {
                    if (this.isMenuOpen === false) {
                        this.settingsDropdown = false;
                    }
                }
            },

            logoutPopup(from) {
                this.toggleSettings();
                this.logoutFrom = from;
                if (from === '') {
                    this.logout.message = "Are you sure you want to logout?";
                } else if (from === 'other sessions') {
                    this.logout.message = "Are you sure you want to logout from other sessions?";
                } else {
                    this.logout.message = "Are you sure you want to logout from all sessions?";
                }
                this.logout.state = true;
            },

            confirmLogout() {
                if (this.logoutFrom === '') {
                    Api.post('logout')
                        .then(() => {
                            localStorage.removeItem('token');
                            localStorage.removeItem('authUser');
                            localStorage.removeItem('current_organization');
                            Themes.resetTheme();
                            this.$router.push('/login');
                        })
                        .catch(() => {
                            this.logoutError.state = true;
                        })
                        .finally(() => {
                            this.clearErrors()
                        });
                } else if (this.logoutFrom === 'other sessions') {
                    Api.post('logout/sessions')
                        .catch(() => {
                            this.logout.state = true;
                        })
                        .finally(() => {
                            this.clearErrors()
                        });
                } else {
                    Api.post('logout/everywhere')
                        .then(() => {
                            localStorage.removeItem('token');
                            localStorage.removeItem('authUser');
                            localStorage.removeItem('current_organization');
                            Themes.resetTheme();
                            this.$router.push('/login');
                        })
                        .catch(() => {
                            this.logoutError.state = true;
                        })
                        .finally(() => {
                            this.clearErrors()
                        });
                }
            },

            clearErrors() {
                this.logout.state = false;
                this.logoutError.state = false;
            },

            popupContents() {
                if (this.logout.state === true) {
                    return this.logout;
                } else if (this.logoutError.state === true) {
                    return this.logoutError;
                }
            },

            toggleChangeOrg() {
                this.settingsDropdown = false;
                this.organizations = JSON.parse(localStorage.getItem('authUser')).organizations
                this.currentOrganization = this.organizations.find(org => {
                    return org.id === JSON.parse(localStorage.getItem('current_organization'));
                });
                this.changingOrg = !this.changingOrg;
            },

            makeCurrent(event) {
                localStorage.setItem('current_organization', event.target.value);
                this.$router.go();
            }
        }
    }
</script>

<style type="text/css">
    .nav-bar {
        position: fixed;
        top: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 64px;
        background-color: var(--darker);
        font-family: 'euclid';
        font-weight: bold;
        z-index: 2;
    }
    .homepage {
        text-decoration: none !important;
        margin: 0 0 3px 114px;
        font-size: 35px;
        color: white;
    }
    .nav-buttons {
        text-decoration: none;
        font-family: 'euclid';
        font-size: 18px;
        font-weight: 500;
        color:  white;
        margin-bottom: 2px;
        float: left;
    }
    .profile-page-nav,
    .staff-page-nav {
        margin-right: 15px;
    }
    .settings-links {
        animation-name: appear;
        animation-duration: 0.5s;
    }
    .router-link-active,
    .router-link-exact-active,
    .settings-links:hover {
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 3px;
    }
    .settings-button {
        background-color: var(--darker);
        border: none;
        outline: none;
        padding: 0;
        height: 24px;
    }
    .settings-dropdown {
        display: none;
    }
    .settings-open {
        position: absolute;
        top: 64px;
        right: 0;
        height: 150px;
        width: 270px;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        color: white;
        background-color: var(--darker);
        font-size: 18px;
        font-weight: 500;
        border-bottom-left-radius: 10px;
        animation-name: settings-dropdown;
        animation-duration: 0.5s;
    }
    .hamburger-button {
        display: none;
        margin-right: 10px;
        border: none;
        outline: none;
        background-color: var(--darker);
    }
    .nav-links-container {
        margin-right: 114px;
    }
    .full-page-cover .choose-org-div {
        padding: 0 30px;
        position: relative;
    }
    .choose-org {
        margin-bottom: 20px;
        padding: 0 8px;
        background-color: var(--darker);
        color: white;
        height: 30px;
        width: 100%;
        font-family: 'euclid';
        font-size: 16px;
        font-weight: 500;
        border: none;
        border-radius: 5px;
        outline-color: var(--darkest);
    }
    .org-change-exit-button {
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 0;
        border: none;
        outline: none;
        background-color: white;
    }
    .organization {
        border: none;
        outline: none;
        font-weight: 500;
    }
    .organization:hover {
        box-shadow: var(--lightgrey);
    }
    @media screen and (max-width: 500px) {
        .nav-links-container {
            display: none;
        }
        .menu-open {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            position: fixed;
            background-color: var(--darker);
            padding-left: 30px;
            top: 64px;
            width: 100%;
            height: 120px;
            animation-name: dropdown;
            animation-duration: 0.5s;
        }
        .menu-open .nav-buttons {
            animation-name: appear;
            animation-duration: 0.5s;
        }
        .settings-open {
            top: 184px;
            left: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 10px;
        }
        .settings-button {
            text-align: left;
        }
        .hamburger-button {
            display: block;
        }
        .homepage {
            margin-left: 30px;
        }
    }
    @keyframes settings-dropdown {
        0% {
            height: 0;
        }
        100% {
            height: 150px;        
        }
    }
    @keyframes dropdown {
        0% {
            height: 0;
        }
        100% {
            height: 120px;
        }
    }
    @keyframes appear {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
</style>
