<template>
    <message-popup
        v-if="openPopup"
        :content="chooseContent()"
        @close-notification="clearErrors()"
        @confirm-action="resendVerification()"
        :actionStatus="sendingEmail"
    ></message-popup>
    <main-card>
        <div class="wishlist-container" :class="{'right-padding-container' : loading}">
            <router-link to="/" class="back-link">
                <back-icon></back-icon>
            </router-link>
            <div v-if="!error && !loading" class="top-container">
                <div class="title-container">
                    <div class="display-name">{{ displayUsername(username) }}</div>
                    <div class="wishlist-title long-title">Birthday Wishlist</div>
                    <div class="wishlist-title short-title">Wishlist</div>
                </div>
                <gift-icon class="gift"></gift-icon>
            </div>
            <div v-if="!error && !loading" class="items-container-2">
                <wishlist-row
                    v-for="item in items"
                    :item="item"
                    :key="item.id"
                    :organization="currentOrganization"
                    wishlist="person"
                    :user="authUser"
                    @reserve-error="openReserveError()"
                ></wishlist-row>
            </div>
            <loader
                v-else-if="loading"
                :loading="loading"
                :finishedLoading="fetchedData"
            ></loader>
            <div v-else class="get-error-message">Something went wrong :(</div>
        </div>
    </main-card>
</template>

<script type="text/javascript">
    import WishlistRow from '../reusable-components/WishlistRow.vue';
    import EmailValidation from '@/mixins/notvalidated.js';
    import { Api } from '@/api.js';
    import Loader from '../layout-components/Loader.vue';
    import BackIcon from '../svgs/BackIcon';
    import GiftIcon from '../svgs/GiftIcon';

    export default {
        name: 'PersonWishlistPage',

        mixins: [EmailValidation],

        components: {
            WishlistRow,
            Loader,
            BackIcon,
            GiftIcon
        },

        inheritAttrs: false,

        data() {
            return {
                authUser: JSON.parse(localStorage.getItem('authUser')),
                currentOrganization: localStorage.getItem('current_organization'),
                username: null,
                id: null,
                items: null,
                error: false,
                loading: false,
                fetchedData: false,
                reserveError: {
                    state: false,
                    message: "Couldn't complete action :(",
                    type: 'notification'
                }
            }
        },

        computed: {
            openPopup() {
                return this.verificationError.state || this.confirmEmail.state || this.emailFailed.state || this.reserveError.state ? true : false;
            }
        },

        methods: {
            displayUsername(username) {
                return username ? username + "'s" : "";
            },

            openReserveError() {
                this.reserveError.state = true;
            },

            chooseContent() {
                if (this.reserveError.state === true) {
                    return this.reserveError;
                } else {
                    return this.popupContentMixin();
                }
            },

            clearErrors() {
                this.reserveError.state = false;
                this.clearErrorsMixin()
            }
        },

        activated() {
            this.id = this.$route.params.id;
            this.loading = true;
            let fetchedUser = false;
            let fetchedItems = false;
            let executeFirstFinally = false;
            let executeSecondFinally = false;
            Api.get(`organization/${this.currentOrganization}/user/${this.id}/items`)
                .then(response => {
                    this.items = response.data;
                    fetchedItems = true;
                    if (fetchedUser === true) {
                        executeFirstFinally = true;
                        this.fetchedData = true;
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = true;
                    if (error.response.data === 403) {
                        this.verificationError.state = true;
                    }
                })
                .finally(() => {
                    if (executeFirstFinally) {
                        this.loading = false;
                        this.fetchedData = false;
                    }
                });
            Api.get(`organization/${this.currentOrganization}/user/${this.id}`)
                .then(response => {
                    this.username  = response.data.first_name;
                    fetchedUser = true;
                    if (fetchedItems === true) {
                        executeSecondFinally = true;
                        this.fetchedData = true;
                    }
                })
                .catch(() => {
                    this.loading = false;
                    this.error = true;
                })
                .finally(() => {
                    if (executeSecondFinally) {
                        this.loading = false;
                        this.fetchedData = false;
                    }
                });
        }
    }
</script>

<style type="text/css" scoped>
    .wishlist-container {
        padding: 45px 0 0 60px;
        position: relative;
        height: inherit;
        width: 100%;
        border: 1px solid var(--lightgrey);
        border-radius: 20px;
    }
    .right-padding-container {
        padding-right: 60px;
    }
    .items-container-2 {
        height: 68%;
        padding-right: 60px;
        overflow-y: overlay;
    }
    .top-container {
        display: flex;
        justify-content: space-between;
        height: 121px;
        margin-bottom: 10px;
    }
    .title-container {
        display: inline-block;
    }
    .display-name {
        max-width: 340px;
    }
    .display-name,
    .wishlist-title {
        font-size: 40px;
        font-weight: bold;
        color: var(--darkest);
        height: 45%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

    }
    .wishlist-title {
        width: 340px;
        padding-bottom: 7px;
        margin-bottom: 20px;
        border-bottom: 1px solid var(--darkest);
    }
    .long-title {
        display: block;
    }
    .short-title {
        display: none;
    }
    .gift {
        transform: translate(-70px, -11px);
    }
    .back-link {
        position: absolute;
        top: 21px;
        left: 22px;
        padding: 2px;
    }
    .get-error-message {
        padding-right: 60px;
    }
    @media screen and (max-width: 650px) {
        .long-title {
            display: none;
        }
        .short-title {
            display: block;
            width: 118px;
        }
        .display-name {
            max-width: 200px;
        }
        .display-name,
        .wishlist-title {
            font-size: 30px;
            height: 40px;
        }
        .gift {
            height: 90px;
            margin-right: 20px;
        }
        .items-container-2 {
            height: 73%;
        }
        .top-container {
            height: 90px;
        }
    }
    @media screen and (max-width: 480px) {
        .title-container,
        .short-title {
            width: 100%;
        }
        .title-container {
            padding-right: 60px;
        }
        .gift {
            display: none;
        }
    }
</style>