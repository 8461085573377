<template>
    <div class="page-cover" @click.self="closeForm()">
        <div class="item-form-container">
            <a class="close-icon" @click="closeForm()">
                <x-icon></x-icon>
            </a>
            <label class="my-labels">Item Name
                <input type="text" name="item-name" class="inputs long-inputs" v-model="currentItem.name" v-focus>
                    <p class="error-messages" v-for="error in errors.name" :key="error">
                        <x-icon class="x-icon"></x-icon>
                        {{ error }}
                    </p>
            </label>
            <label class="my-labels">Link
                <input type="text" name="item-link" class="inputs long-inputs" v-model="currentItem.link">
                    <p class="error-messages" v-for="error in errors.link" :key="error">
                        <x-icon class="x-icon"></x-icon>
                        {{ error }}
                    </p>
            </label>
            <label class="my-labels">Price
                <input type="number" name="item-price" class="inputs long-inputs" v-model="currentItem.price">
                    <p class="error-messages" v-for="error in errors.price" :key="error">
                        <x-icon class="x-icon"></x-icon>
                        {{ error }}
                    </p>
            </label>
            <label class="my-labels">Color
                <input type="text" name="item-color" class="inputs long-inputs" v-model="currentItem.color">
            </label>
            <button class="save-button" :disabled="savedisabled" @click="saveItem()">SAVE CHANGES</button>
        </div>
    </div>
</template>

<script type="text/javascript">

    export default {
        name: 'ItemInfoForm',

        props: ['item', 'savedisabled', 'errors'],

        data() {
            return {
                currentItem: this.item,
                itemBeforeEdit: null,
            }
        },

        created() {
            this.itemBeforeEdit = Object.assign({}, this.currentItem);
        },

        methods: {
            closeForm() {
                this.$emit('close-form');
                Object.assign(this.currentItem, this.itemBeforeEdit);
            },

            saveItem() {
                this.$emit('save-item', this.currentItem);
            },
        }
    }
</script>

<style>
    .page-cover {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        cursor: pointer;
        overflow-y: overlay;
    }
    .item-form-container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 440px;
        width: 100%;
        max-width: 500px;
        margin: 0 8px;
        background-color: white;
        border: 1px solid var(--lightgrey);
        border-radius: 20px;
        padding: 35px;
        cursor: default;
    }
    .item-form-container .my-labels {
        width: 230px;
    }
    .item-form-container .inputs {
        width: 100%;
    }
    .close-icon {
        position: absolute;
        top: 13px;
        right: 13px;
        padding: 2px;
    }
    @media screen and (max-height: 500px) {
        .item-form-container {
            margin-top: 60px;
        }
    }
    @media screen and (max-height: 450px) {
        .item-form-container {
            margin-top: 120px;
        }
    }
    @media screen and (max-height: 400px) {
        .item-form-container {
            margin-top: 180px;
        }
    }
</style>
