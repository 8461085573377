<template>
	<div class="loader-container">
		<svg width="112" height="298" viewBox="0 0 112 298" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
                <path d="M56.9187 136.591C51.9957 143.454 46.8853 150.834 46.4649 159.272C45.8217 172.233 56.1894 182.833 60.8187 194.958C62.825 200.3 63.7331 205.991 63.4885 211.691C63.244 217.391 61.8519 222.984 59.3955 228.133C57.1365 232.803 53.9153 237.442 54.3306 242.614C54.5687 245.769 56.1489 248.641 57.4202 251.543C60.5403 258.816 61.9018 266.723 61.394 274.621C60.8862 282.519 58.5236 290.186 54.4977 297" stroke="url(#paint0_linear)" stroke-width="2" stroke-miterlimit="10"/>
                <path d="M57.5265 136.591C52.6085 143.479 47.4981 150.839 47.0676 159.272C46.4243 172.233 56.7921 182.833 61.4214 194.958C63.4277 200.3 64.3358 205.991 64.0912 211.691C63.8467 217.391 62.4546 222.984 59.9982 228.133C57.7392 232.803 54.518 237.442 54.9333 242.614C55.1714 245.769 56.7516 248.641 58.0229 251.543C61.143 258.816 62.5045 266.723 61.9967 274.621C61.4889 282.519 59.1263 290.186 55.1004 297" stroke="#535461" stroke-width="2" stroke-miterlimit="10"/>
                <path class="fixed-balloon" d="M62.6825 140.451H49.2708L51.6209 132.408H59.6638L62.6825 140.451Z"/>
                <path class="fixed-balloon" d="M111.285 55.6449C111.285 86.3784 86.3708 131.4 55.6424 131.4C24.914 131.4 0 86.3734 0 55.6449C0 40.887 5.86257 26.7335 16.298 16.298C26.7335 5.86257 40.887 0 55.6449 0C70.4029 0 84.5564 5.86257 94.9918 16.298C105.427 26.7335 111.29 40.887 111.29 55.6449H111.285Z" fill="#FAA496"/>
                <path class="changing-balloon" :class="{'finished-loading' : loaderFull, 'loader-balloon' : !loaderFull}" d="M111.285 55.6449C111.285 86.3784 86.3708 131.4 55.6424 131.4C24.914 131.4 0 86.3734 0 55.6449C0 40.887 5.86257 26.7335 16.298 16.298C26.7335 5.86257 40.887 0 55.6449 0C70.4029 0 84.5564 5.86257 94.9918 16.298C105.427 26.7335 111.29 40.887 111.29 55.6449H111.285Z" fill="#D66D5C"/>
                <path d="M59.6639 132.742H51.6209L46.9258 130.058H64.359L59.6639 132.742Z" fill="#535461"/>
                <path :class="{'finished-loading' : loaderFull, 'loader-balloon' : !loaderFull}" opacity="0.1" d="M35.5299 12.4012C35.5299 12.4012 12.0644 25.1393 8.71152 50.6155L35.5299 12.4012Z" fill="white"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear" x1="10570.4" y1="85268.7" x2="11181.4" y2="85268.7" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#808080" stop-opacity="0.25"/>
                    <stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
                    <stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
                </linearGradient>
            </defs>
        </svg>
	</div>
</template>

<script type="text/javascript">
	export default {
		name: 'Loader',

		props: {
			finishedLoading: {
				type: Boolean,
				required: true
			},

			loading: {
				type: Boolean,
				required: true
			}
		},

		data() {
			return {
				loaderFull: this.finishedLoading,
				stillLoading: this.loading
			}
		}
	}
</script>

<style type="text/css">
	.loader-container {
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.loader-balloon {
        animation-name: scaling;
        animation-duration: 2s;
        animation-direction: alternate;
    }
    .finished-loading {
        animation-name: finished-scaling;
        animation-duration: 1s;
    }
    .changing-balloon {
        fill: var(--secondballoon);
    }
    .fixed-balloon {
        fill: var(--thirdballoon);
    }
    @keyframes scaling {
        0% {
            transform: scale(0);
            transform-origin: 50% 44%;
        }
        100% {
            transform: scale(0.99);
            transform-origin: 50% 44%;
        }
    }
    @keyframes finished-scaling {
        100% {
            transform: scale(1.0);
            transform-origin: 50% 44%;
        }
    }
</style>