<template>
    <main-card>
        <div class="table-container">
            <table class="birthdays-table">
                <thead class="table-header-container">
                    <tr class="header-row">
                        <th class="table-headers">
                            <button class="header-buttons" @click="sort('first_name')">
                                Name  <img class="chevrons" :class="sortOrder['first_name']" src="@/assets/vectors/chevron.svg">
                            </button>
                        </th>
                        <th class="table-headers">
                            <button class="header-buttons" @click="sort('date_of_birth')">
                                Birthday  <img class="chevrons" :class="sortOrder['date_of_birth']" src="@/assets/vectors/chevron.svg">
                            </button>
                        </th>
                        <th class="table-headers">
                            <button class="header-buttons" @click="sort('gender')">
                                Gender  <img class="chevrons" :class="sortOrder['gender']" src="@/assets/vectors/chevron.svg">
                            </button>
                        </th>
                        <th class="table-headers">
                            <div class="header-buttons-container">
                                <button  @click="toggleSearch()" class="header-buttons search-button">
                                    <img class="search-icon" src="@/assets/vectors/white-search-icon.svg">
                                </button>
                                <button v-if="orgOwner" class="header-buttons add-button" @click="addUser()">
                                    <img class="plus-icon" src="@/assets/vectors/plus-icon.svg">
                                </button>
                            </div>
                        </th>
                    </tr>
                    <tr class="search-row" v-if="searching">
                        <td colspan="4" class="search-container">
                            <input type="text" class="search-input" placeholder="Search a name or date" v-model="search" v-focus>
                        </td>
                    </tr>
                </thead>
                <tbody v-if="error" class="empty-body" :class="{'smaller-body' : searching}">
                    <div class="get-error-message">Couldn't fetch users :(</div>
                </tbody>
                <tbody v-else-if="loading" class="loader-div" :class="{'smaller-body' : searching}">
                    <div class="balloon-container">
                        <loader
                            :finishedLoading="fetchedUsers"
                            :loading="loading"
                        ></loader>
                    </div>
                </tbody>
                <tbody v-else class="staff-container" :class="{'smaller-body' : searching}">
                    <staff-page-row 
                        v-for="employee in newAllUsers"
                        :person="employee"
                        :key="employee.id"
                        :formatdate="formatDate(employee.date_of_birth)"
                        :hasDelete="orgOwner"
                        @delete-user="deleteUser($event)"
                    ></staff-page-row>
                </tbody>
                <tfoot class="table-footer">
                    <tr class="empty-footer">
                        <td colspan="4" class="empty-cell">
                            <div class="border-div"></div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </main-card>
    <add-user-form 
        v-if="addingUser"
        @close="addUser()"
    ></add-user-form>
    <message-popup
        v-if="openPopup"
        :content="chooseContent()"
        :actionStatus="chooseActionStatus()"
        @confirm-action="chooseAction()"
        @close-notification="clearErrors()"
    ></message-popup>
</template>

<script type="text/javascript">
    import StaffPageRow from '../reusable-components/StaffPageRow.vue';
    import AddUserForm from '../popups/AddUserForm.vue';
    import EmailValidation from '@/mixins/notvalidated.js';
    import { Api } from '@/api.js';
    import Loader from '../layout-components/Loader.vue';

    export default {
        name: 'StaffPage',

        components: {
            StaffPageRow,
            AddUserForm,
            Loader
        },

        mixins: [EmailValidation],

        props: ['width'],

        data() {
            return {
                authUser: JSON.parse(localStorage.getItem('authUser')),
                currentOrganization: localStorage.getItem('current_organization'),
                orgOwner: false,
                allUsers: null,
                newAllUsers: null,
                newAllUsersCopy: null,
                searching: false,
                search: '',
                error: false,
                currentSortOrder: null,
                selectedUser: null,
                deletingUser: false,
                addingUser: false,
                loading: false,
                fetchedUsers: false,

                deleting: {
                    state: false,
                    message: "Are you sure you want to delete this user?",
                    type: "confirm-message"
                },

                deleteError: {
                    state: false,
                    message: "Couldn't delete user :(",
                    type: "notification"
                },

                sortOrder: {
                    first_name: 'notsorted',
                    date_of_birth: 'notsorted',
                    gender: 'notsorted'
                },

                sortTransition: {
                    notsorted: 'ascending',
                    ascending: 'descending',
                    descending: 'notsorted'
                }
            }
        },

        computed: {
            openPopup() {
                return this.verificationError.state || this.confirmEmail.state || this.emailFailed.state || this.deleting.state || this.deleteError.state ? true : false;
            }
        },

        created() {
            this.getUsers();
            let userOrganizations = this.authUser.organizations;
            let currentOrganization = userOrganizations.find(x => x.id == localStorage.getItem('current_organization'));
            if (this.authUser.id === currentOrganization.owner_id) {
                this.orgOwner = true;
            }
        },
        

        watch: {
            search: function() {
                this.newAllUsers = this.newAllUsersCopy.filter(user => {
                    const firstName = user.first_name.toLowerCase();
                    const lastName = user.last_name.toLowerCase();
                    const name = `${firstName} ${lastName}`;
                    const date = this.formatDate(user.date_of_birth).toLowerCase();
                    return name.includes(this.search.toLowerCase()) || date.includes(this.search.toLowerCase())
                });
                Object.keys(this.sortOrder).forEach(key => {
                    if (this.sortOrder[key] !== 'notsorted') {
                        this.sortNewArray(key);
                    }
                });
            }
        },

        methods: {
            getUsers() {
                this.loading = true;
                Api.get(`organization/${this.currentOrganization}/users`)
                    .then(response => {
                        this.allUsers = response.data;
                        this.newAllUsers = this.allUsers.slice();
                        var removeIndex = this.newAllUsers.map(function(user) { return user.id; }).indexOf(this.authUser.id);
                        this.newAllUsers.splice(removeIndex, 1);
                        this.newAllUsersCopy = this.newAllUsers.slice();
                        setTimeout (() => {
                            this.fetchedUsers = true;
                        }, 2000)
                    })
                    .catch((error) => {
                        this.error = true;
                        if (error.response.status === 403) {
                            this.verificationError.state = true;
                        }
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.loading = false;
                            this.fetchedUsers = false;
                        }, 2000)
                    });
            },

            confirmDelete() {
                this.deletingUser = true;
                Api.delete(`organization/${this.currentOrganization}/user/${this.selectedUser.id}`)
                    .then(() => {
                        this.removeUserFromArray(this.newAllUsers, this.selectedUser);
                        this.removeUserFromArray(this.newAllUsersCopy, this.selectedUser);
                    })
                    .catch(() => {
                        this.deleteError.state = true;
                    })
                    .finally(() => {
                        this.deletingUser = false;
                        this.deleting.state = false;
                        this.selectedUser = null;
                    })
            },

            sort(sortBy) {
                this.sortPrep(sortBy);
                this.sortNewArray(sortBy);
            },

            sortPrep(sortBy) {
                this.sortOrder[sortBy] = this.sortTransition[this.sortOrder[sortBy]];
                this.currentSortOrder = this.sortOrder[sortBy];
                let sortOrder = this.sortOrder;
                Object.keys(sortOrder).forEach(function(key) {
                    if (key !== sortBy) {
                        sortOrder[key] = 'notsorted';
                    }
                });
            },

            sortNewArray(sortBy) {
                if (this.currentSortOrder !== 'notsorted') {
                    return this.newAllUsers.sort((a, b) => {
                        let modifier = this.currentSortOrder === 'ascending' ? 1 : -1;

                        if (this.sortReference(a, sortBy) <= this.sortReference(b, sortBy)) {
                            return -1 * modifier;
                        } else {
                            return modifier;
                        }
                    });
                } else {
                    return this.newAllUsers = this.allUsers.slice().filter(user => this.newAllUsers.includes(user));
                }
            },

            sortReference(user, sortBy) {
                if (sortBy === 'date_of_birth') {
                    return `${user[sortBy].substring(5,7)}${user[sortBy].substring(8)}`;
                } else {
                    return user[sortBy] === null ? '0' : user[sortBy].toLowerCase();
                }
            },

            formatDate(date) {
                if (window.innerWidth > 500) {
                    return new Date(date).toLocaleString('default', { month: 'long', day: 'numeric' });
                } else {
                    return new Date(date).toLocaleString('default', { month: 'short', day: 'numeric' });
                }
            },

            toggleSearch() {
                this.searching = !this.searching;
                this.search = '';
            },

            addUser() {
                this.addingUser = !this.addingUser;
            },

            deleteUser(user) {
                this.selectedUser = user;
                this.deleting.state = true;
            },

            chooseAction() {
                if (this.deleting.state === true) {
                    this.confirmDelete()
                } else {
                    this.resendVerification()
                }
            },

            chooseActionStatus() {
                if (this.deleting.state === true) {
                    return this.deletingUser;
                } else {
                    return this.sendingEmail;
                }
            },

            chooseContent() {
                if (this.deleteError.state === true) {
                    return this.deleteError;
                } else if (this.deleting.state === true) {
                    return this.deleting;
                } else {
                    return this.popupContentMixin();
                }
            },

            clearErrors() {
                this.deleteError.state = false;
                this.deleting.state = false;
                this.clearErrorsMixin();
            },

            removeUserFromArray(array, person) {
                const removeIndex = array.map(function(user) { return user.id; }).indexOf(person.id);
                array.splice(removeIndex, 1);
            }
        }
    }
</script>

<style>
    .table-container {
        height: 100%;
    }
    .birthdays-table {
        height: 100%;
        width: 100%;
        max-width: 700px;
        border-radius: 20px;
        border-collapse: collapse;
        background-color: white;
        overflow: hidden;
        table-layout: fixed;
    }
    .header-row {
        background-color: var(--darker);
        height: 51px;
        color: white;
    }
    .table-headers {
        text-align: left;
    }
    .header-buttons {
        padding-left: 0;
        background-color: var(--darker);
        border: none;
        color: white;
        font-size: 18px;
        font-weight: 500;
        display: flex;
        align-items: center;
    }
    .header-buttons-container {
        display: flex;
    }
    .search-button {
        margin-left: 17px;
    }
    .plus-icon {
        width: 28px;
        height: 28px;
    }
    .chevrons {
        margin-left: 5px;
    }
    .notsorted {
        transform: rotate(-90deg);
    }
    .ascending {
        transform: rotate(180deg);
    }
    .descending {
        transform: rotate(0);
    }
    .search-row {
        border-bottom: 1px solid var(--lightgrey);
        width: 100%;
    }
    .search-container {
        text-align: center;
    }
    .search-input {
        width: 90%;
        margin: 3px 5%;
        padding-left: 37px;
        height: 32px;
        background-color: var(--lightergrey);
        border: 1px solid var(--grey);
        border-radius: 5px;
        background-image: url(../../assets/vectors/search-icon.svg);
        background-repeat: no-repeat;
        background-position: 10px 6px;
        font-family: 'euclid';
        font-size: 14px;
        font-style: italic;
        color: black;
    }
    .search-input:focus {
        outline-color: var(--darker);
    }
    .staff-container,
    .empty-body,
    .balloon-container {
        display: block;
        height: 470px;
        width: 285.7%;
        overflow-y: overlay;
    }
    .smaller-body {
        height: 429px;
    }
    .get-error-message {
        width: 100%;
        padding-top: 150px;
        text-align: center;
        color: var(--darkest);
        font-size: 25px;
    }
    .empty-cell {
        height: 21px;
    }
    .border-div {
        height: 100%;
        width: 90%;
        margin: 0 5%;
        border-top: 2px solid var(--lightgrey);
    }
    @media screen and (min-width: 500px) {
        .table-headers:nth-child(1) {
            padding-left: 6.3%;
        }
        .table-headers:nth-child(2) {
            width: 25%;
            padding-left: 4.6%;
        }
        .table-headers:nth-child(3) {
            width: 25%;
            padding-left: 3.3%;
        }
        .table-headers:nth-child(4) {
            width: 15%;
        }
    }
    @media screen and (max-width: 500px) {
        .table-headers {
            width: 25%;
            padding-left: 12px;
        }
        .table-headers:nth-child(1) {
            padding-left: 6%;
        }
        .table-headers:nth-child(2) {
            padding-left: 11%;
        }
        .table-headers:nth-child(3) {
            padding-left: 12%;
        }
        .table-headers:nth-child(4) {
            padding-left: 5%;
        }
        .header-buttons {
            font-size: 15px;
        }
        .staff-container,
        .empty-body,
        .balloon-container {
            width: 400%;
        }
        .search-button {
            padding: 0;
            margin-left: 12px;
        }
        .add-button {
            margin-left: 5px;
        }
        .search-icon {
            height: 18px;
            width: 18px;
        }
        .plus-icon {
            width: 22px;
            height: 22px;
        }
    }
    @media screen and (max-width: 350px) {
        .header-buttons {
            font-size: 14px;
        }
    }
    @media screen and (max-height: 700px) {
        .table-container {
            height: 100%;
        }
        .birthdays-table {
            height: 100%;
        }
        .staff-container,
        .empty-body,
        .balloon-container {
            height: 408px;
        }
        .smaller-body {
            height: 367px;
        }
    }
</style>
