<template>
    <main-card>
        <div class="static-nav-bar nav-bar">
            <p class="homepage">SBC</p>
        </div>
        <div class="login-container">
            <div class="login-header">
                Sign In
            </div>
            <div v-if="error" class="get-error-message">
                Something went wrong :(
            </div>
            <div v-else>
                <div class="welcome-message">
                    Never miss your coworkers' birthdays!
                </div>
                <div class="login-form-container">
                    <label class="my-labels login-labels">
                        Email
                        <input class="long-inputs inputs" type="Email" name="email" v-model="email" v-focus>
                        <p v-for="error in errors.email" :key="error" class="error-messages">
                            <x-icon class="x-icon"></x-icon>
                            {{ error }}
                        </p>
                    </label>
                    <label class="my-labels login-labels">
                        Password
                        <input class="long-inputs inputs" type="Password" name="password" v-model="password">
                        <p v-for="error in errors.password" :key="error" class="error-messages">
                            <x-icon class="x-icon"></x-icon>
                            {{ error }}
                        </p>
                    </label>
                    <button class="login-button" @click="login()" :disabled="loggingIn">LOGIN</button>
                    <a class="login-links" @[canClick]="forgotPassword()">Forgot Password?</a>
                    <router-link class="login-links" to="/register">Register an Organization</router-link>
                </div>
            </div>
        </div>
    </main-card>
    <message-popup
        v-if="loginError.state || forgetPasswordError.state || forgetPasswordSuccess.state"
        :content="chooseContent()"
        @close-notification="clearErrors()"
    ></message-popup>
</template>

<script type="text/javascript">
    import { Api } from '@/api.js';
    import axios from 'axios';
    import { Validator } from '../../classes/validator.js';

    export default {
        name: 'LoginPage',

        emits: ['logged-in'],

        data() {
            return {
                email: '',
                password: '',
                loggingIn: false,
                sendingResetPass: false,
                error: false,
                rules: {
                    email: "email|required",
                    password: "required"
                },
                forgotPasswordRules: {
                    email: "email|required"
                },
                errors: {
                    password: []
                },
                loginError: {
                    state: false,
                    message: "Couldn't sign in :(",
                    type: 'notification'
                },
                forgetPasswordError: {
                    state: false,
                    message: "We couldn't reset your password :(",
                    type: 'notification'
                },
                forgetPasswordSuccess: {
                    state: false,
                    message: "We sent you an email to reset your password!",
                    type: 'notification'
                },
                authTokenId: null,
                themeObject: this.colorObject
            }
        },

        computed: {
            canClick() {
                return this.sendingResetPass === false ? "click" : null;
            }
        },

        created() {
            axios.get(process.env.VUE_APP_BASE_URL + "sanctum/csrf-cookie")
                .catch(() => this.error = true);
        },

        methods: {
            login() {
                const userLoginInfo = {email: this.email, password: this.password};
                const {success, errors} = Validator.validate(userLoginInfo, this.rules);
                this.errors = errors;
                if (success) {
                    this.loggingIn = true;
                    Api.post('login' , {
                        email: this.email,
                        password: this.password
                    }).then(response => {
                        localStorage.setItem('token', response.data.token);
                    }).then(() => {
                        Api.get('user')
                            .then(response => {
                                localStorage.setItem('authUser', JSON.stringify(response.data));
                                localStorage.setItem('current_organization', response.data.primary_organization);
                                this.$router.go("/");
                            })
                    }).catch(error => {
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors;
                        } else if (error.response.status === 401) {
                            this.errors.password = error.response.data;
                        } else {
                            this.loginError.state = true;
                        }
                    }).finally(() => this.loggingIn = false);
                }
            },

            forgotPassword() {
                const email = {email: this.email};
                const {success, errors} = Validator.validate(email, this.forgotPasswordRules);
                this.errors = errors;
                if (success) {
                    this.sendingResetPass = true;
                    Api.post('forgot-password', email)
                        .then(() => {
                            this.forgetPasswordSuccess.state = true;
                        })
                        .catch((error) => {
                            if (error.response.status === 404) {
                                this.errors = error.response.data.errors
                            } else {
                                this.forgetPasswordError.state = true;
                            }
                        })
                        .finally(() => {
                            setTimeout(() => {
                                this.sendingResetPass = false;
                            }, 60000)
                        })
                }
            },

            chooseContent() {
                if (this.loginError.state === true) {
                    return this.loginError;
                } else if (this.forgetPasswordSuccess.state === true) {
                    return this.forgetPasswordSuccess;
                } else if (this.forgetPasswordError.state === true) {
                    return this.forgetPasswordError;
                }
            },

            clearErrors() {
                this.loginError.state = false;
                this.forgetPasswordSuccess.state = false;
                this.forgetPasswordError.state = false;
            }
        }
    }
</script>

<style type="text/css">
    .nav-bar {
        position: fixed;
        top: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 64px;
        background-color: var(--darker);;
        font-family: 'euclid';
        font-weight: bold;
        z-index: 2;
    }
    .static-nav-bar {
        left: 0;
    }
    .login-header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 51px;
        background-color: var(--darker);
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        font-size: 18px;
        font-weight: 500;
        color: white;
    }
    .login-form-container {
        height: 421px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 0 90px 0;
    }
    .login-form-container label {
        width: 230px;
    }
    .login-form-container label .error-messages {
        margin-top: 2px;
    }
    .welcome-message {
        text-align: center;
        padding: 20px 10px;
        height: 70px;
        font-size: 25px;
        font-weight: bold;
        font-style: italic;
        color: var(--darker);
    }
    .login-labels {
        margin: 15px 0;
    }
    .login-button {
        width: 200px;
        height: 38px;
        margin: 40px 0 6px 0;
        font-family: 'euclid';
        font-size: 16px;
        font-weight: bold;
        border: none;
        border-radius: 5px;
        background-color: var(--darker);
        color: white;
    }
    .login-links {
        margin-top: 5px;
        font-size: 16px;
        color: var(--darkest);
        text-decoration: underline;
    }
</style>