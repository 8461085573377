export class Themes {
    static themes = {
        cottonCandy: {
            name:"Cotton Candy",
            darkest: "#AC5243",
            darker: "#D66D5C",
            light: "#F4BAB0",
            firstballoon: "#E77765",
            secondballoon: "#E2634E",
            thirdballoon: "#FAA496"
        },
        aqua: {
            name: "Aqua",
            darkest: "#1391BB",
            darker: "#1CB3E6",
            light: "#B0E3F4",
            firstballoon: "#34C1F0",
            secondballoon: "#11B8DD",
            thirdballoon: "#00BFFF"
        },
        royal: {
            name: "Royal",
            darkest: "#55187A",
            darker: "#7C49BD",
            light: "#D1ACFF",
            firstballoon: "#9665E7",
            secondballoon: "#9842C0",
            thirdballoon: "#622BA9"
        },
        orangeBlossom: {
            name: "Orange Blossom",
            darkest: "#7A4218",
            darker: "#F18E1A",
            light: "#FFDEAC",
            firstballoon: "#E79465",
            secondballoon: "#F9B44D",
            thirdballoon: "#FFCE85"
        },
        forest: {
            name: "Forest",
            darkest: "#2C5131",
            darker: "#417A48",
            light: "#ACE094",
            firstballoon: "#76B060",
            secondballoon: "#7EAF3B",
            thirdballoon: "#4A6C34"
        },
        retro: {
            name: "Retro",
            darkest: "#161616",
            darker: "#383838",
            light: "#A3A3A3",
            firstballoon: "#6E6E6E",
            secondballoon: "#363636",
            thirdballoon: "#C0C0C0"
        },
        ocean: {
            name: "Ocean",
            darkest: "#1A187A",
            darker: "#495CBD",
            light: "#7694E0",
            firstballoon: "#6572E7",
            secondballoon: "#424EC0",
            thirdballoon: "#2D2BA9"
        },
    }


    static getTheme = () => {
        let currentTheme = localStorage.getItem('current-theme');
        if (currentTheme) {
            currentTheme = JSON.parse(currentTheme);
        } else {
            let keys = Object.keys(this.themes);
            currentTheme = this.themes[keys[ keys.length * Math.random() << 0 ]]
            this.setTheme(currentTheme);
        }
        return currentTheme;
    }

    static setTheme = (theme) => {
        localStorage.setItem('current-theme', JSON.stringify(theme));
    }

    static changeTheme = (theme) => {
        let root = document.documentElement;
        Object.keys(theme).forEach(key => {
            if (key !== 'name') {
                root.style.setProperty(`--${key}`, theme[key]);
            }
        })
    }

    static resetTheme = () => {
        localStorage.removeItem('current-theme');
        const newTheme = this.getTheme();
        this.changeTheme(newTheme);
    }
}
