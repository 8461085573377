import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '../components/pages/LoginPage.vue';
import RegisterPage from '../components/pages/RegisterPage.vue';
import StaffPage from '../components/pages/StaffPage.vue';
import ProfilePage from '../components/pages/ProfilePage.vue';
import PersonWishlistPage from '../components/pages/PersonWishlistPage.vue';
import MyWishlistPage from '../components/pages/MyWishlistPage.vue';

const routes = [
    {
        path: '/login',
        component: LoginPage,
        name: 'login',
        props: true,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/register',
        component: RegisterPage,
        name: 'register',
        props: true,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/',
        component: StaffPage,
        name: 'staff-page',
        props: true,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/wishlist/:id',
        component: PersonWishlistPage,
        name: 'wishlist',
        props: true,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/profile',
        component: ProfilePage,
        name: 'profile',
        props: true,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/profile/mywishlist',
        component: MyWishlistPage,
        name: 'my-wishlist',
        props: true,
        meta: {
            requiresAuth: true
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (localStorage.getItem("token") === null) {
            next({
                path: "/login",
                name: "login",
                params: { nextUrl: to.fullPath },
            });
        } else {
            next();
        }
    } else if(to.matched.some((record) => record.meta.requiresAuth === false)) {
        if(localStorage.getItem("token") !== null) {
            next(from);
        } else {
            next();
        }
    } else {
        next();
    }
});

export { router };
