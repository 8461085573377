<template>
    <profile-card>
        <div v-if="getError" class="get-error-message">Couldn't fetch info :(</div>
        <div v-else class="form-container">
            <div class="form-row first-row">
                <label class="my-labels first-name-label" tabindex="1">First Name
                    <input placeholder="Johnny" class="inputs" type="text" v-model="currentUser.first_name">
                    <p class="error-messages in-mobile" v-for="error in errors.first_name" :key="error">
                        <x-icon class="x-icon"></x-icon>
                        {{ error }}
                    </p>
                </label>
                <label class="my-labels last-name-label" :class="{'first-name-has-errors' : errors.first_name}" tabindex="2">Last Name
                    <input placeholder="Appleseed" class="inputs" type="text" v-model="currentUser.last_name">
                </label>
                <div class="first-row-errors">
                    <p class="error-messages not-in-mobile" v-for="error in errors.first_name" :key="error">
                        <x-icon class="x-icon"></x-icon>
                        {{ error }}
                    </p>
                    <p class="error-messages last-name-errors" :class="{'less-margin' : errors.last_name}" v-for="error in errors.last_name" :key="error">
                        <x-icon class="x-icon"></x-icon>
                        {{ error }}
                    </p>
                </div>
            </div>
            <div class="form-row">
                <label class="my-labels dob-label" tabindex="5">Date of Birth
                    <input class="inputs long-inputs" type="date" v-model="currentUser.date_of_birth">
                    <p class="error-messages" v-for="error in errors.date_of_birth" :key="error">
                        <x-icon class="x-icon"></x-icon>
                        {{ error }}
                    </p>
                </label>
            </div>
            <div class="form-row">
                <label for="gender" class="my-labels gender-main-label">Gender</label>
                <div class="radio-div">
                    <label for="male-rdb" class="my-labels gender-labels male-label" tabindex="3">
                        <input type="radio" name="gender" id="male-rdb" class="gender-radio" value="male" v-model="currentUser.gender" :checked="checkGender('male')">
                        <span class="fake-rdb"></span>
                        <span class="gender-span">Male</span>
                    </label>
                </div>
                <div class="radio-div">
                    <label for="female-rdb" class="my-labels gender-labels female-label" tabindex="4">
                        <input type="radio" name="gender" id="female-rdb" class="gender-radio" value="female" v-model="currentUser.gender" :checked="checkGender('female')">
                        <span class="fake-rdb"></span>
                        <span class="gender-span">Female</span>
                    </label>
                </div>
                <div class="gender-errors">
                    <p class="error-messages" v-for="error in errors.gender" :key="error">
                        <x-icon class="x-icon"></x-icon>
                        {{ error }}
                    </p>
                </div>
            </div>
            <div class="form-row" :class="{'extra-margin' : errors.current_password}">
                <label class="my-labels old-pass-label" tabindex="2">Old Password
                    <input class="inputs long-inputs" type="password" v-model="passwordObject.current_password">
                    <p class="error-messages old-pass-errors" v-for="error in errors.current_password" :key="error">
                        <x-icon class="x-icon"></x-icon>
                        {{ error }}
                    </p>
                </label>
            </div>
            <div class="form-row">
                <label class="my-labels new-pass-label" tabindex="2">New Password
                    <input class="inputs long-inputs" type="password" v-model="passwordObject.password">
                </label>
                <label class="my-labels confirm-pass-label" :class="{'password-has-errors' : errors.password}" tabindex="2">Confirm New Password
                    <input class="inputs long-inputs" type="password" v-model="passwordObject.password_confirmation">
                </label>
                <div class="last-row-errors">
                    <p class="error-messages" v-for="error in errors.password" :key="error">
                        <x-icon class="x-icon"></x-icon>
                        {{ error }}
                    </p>
                    <p class="error-messages last-name-errors" v-for="error in errors.password_confirmation" :key="error">
                        <x-icon class="x-icon"></x-icon>
                        {{ error }}
                    </p>
                </div>
            </div>
            <div class="save-button-row">
                <button @click="saveChanges()" class="save-button" :disabled="isSaving">SAVE CHANGES</button>
            </div>
        </div>
    </profile-card>
    <message-popup
        v-if="openPopup"
        :content="popupContent()"
        @close-notification="clearErrors()"
        @confirm-action="resendVerification()"
        :actionStatus="sendingEmail"
    ></message-popup>
</template>

<script type="text/javascript">
    import ProfileCard from '../layout-components/ProfileCard';
    import { Api } from '@/api.js';
    import { Validator } from '../../classes/validator.js';
    import EmailValidation from '@/mixins/notvalidated.js';

    export default {
        name: 'ProfilePage',

        inheritAttrs: true,

        components: {
            ProfileCard
        },

        mixins: [EmailValidation],

        data() {
            return {
                currentUser: null,
                isSaving: false,
                openChangePass: false,
                getError: false,
                errors: {},

                passwordObject: {
                    current_password: '',
                    password: '',
                    password_confirmation:'',
                },

                saveError: {
                    state: false,
                    message: "Couldn't save info :(",
                    type: 'notification'
                },

                rules: {
                    first_name: "required",
                    last_name: "required",
                    date_of_birth: "required|date:past",
                    gender: "required"
                },

                passwordRules: {
                    current_password: "required",
                    password: "required",
                    password_confirmation: "required"
                }
            }
        },

        computed: {
            openPopup() {
                return this.verificationError.state || this.confirmEmail.state || this.emailFailed.state || this.saveError.state ? true : false;
            }
        },

        created() {
            return this.getUser();
        },

        activated() {
            return this.getUser();
        },

        methods: {
            checkGender(gender) {
                return this.currentUser.gender === gender ? true : false;
            },

            async saveChanges() {
                this.saveUserChanges();
                this.savePasswordChanges();
            },

            saveUserChanges() {
                const {success, errors} = Validator.validate(this.currentUser, this.rules);
                this.errors = {...errors};
                
                if (success) {
                    this.isSaving = true;
                    Api.put(`user`, this.currentUser)
                    .then(() => {
                        Api.get('user')
                            .then(response => {
                                this.currentUser = response.data;
                                localStorage.setItem('authUser', JSON.stringify(response.data));
                                localStorage.setItem('current_organization', response.data.primary_organization);
                            })
                    })
                    .catch((error) => {
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors;
                        } else if (error.response.status === 403) {
                            this.verificationError.state = true;
                        } else {
                            this.saveError.state = true;
                        }
                    })
                    .finally(() => {
                        this.isSaving = false;
                    });
                } 
            },

            savePasswordChanges() {
                if(this.checkPasswordExists()) {
                    const {success, errors} = Validator.validate(this.passwordObject, this.passwordRules);
                    this.errors = {...errors};

                    if(success) {
                        this.isSaving = true;
                        Api.post('password/change', this.passwordObject)
                        .catch((error) => {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                            } else if (error.response.status === 403) {
                                if (this.verificationError.state === false) {
                                    this.verificationError.state = true;
                                }
                            } else {
                                this.saveError.state = true;
                            }
                        }).finally(() => {
                            this.isSaving = false;
                            Object.keys(this.passwordObject).forEach(key => {
                                this.passwordObject[key] = '';
                            });
                        });
                    }
                }
            },

            checkPasswordExists() {
                return this.passwordObject.current_password !== '';
            },

            clearErrors() {
                this.saveError.state = false;
                this.clearErrorsMixin();
            },

            getUser() {
                return this.currentUser = JSON.parse(localStorage.getItem("authUser"));
            },

            popupContent() {
                if (this.saveError.state === true) {
                    return this.saveError;
                } else {
                    return this.popupContentMixin();
                }
            }
        }
    }
</script>


<style>
    .form-container {
        height: 491px;
        width:  100%;
        padding: 30px 100px;
        border: 2px solid var(--lightgrey);
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    .form-row {
        min-height: 77px;
        width: 100%;
    }
    .my-labels {
        display: inline-block;
        color: var(--darkest);
        text-align: left;
        font-size: 14px;
        max-height: 44px;
        width: 230px;
    }
    .gender-main-label {
        width: 100%;
    }
    .first-name-label,
    .last-name-label {
        width: 125px;
    }
    .first-name-label {
        margin-right: 20px;
    }
    .old-pass-label {
        width: 100%;
        display: block;
        padding-right: 18px;
    }
    .dob-label,
    .new-pass-label {
        padding-right: 18px;
    }
    .confirm-pass-label {
        padding-left: 18px;
    }
    .dob-label,
    .new-pass-label,
    .confirm-pass-label {
        width: 50%;
    }
    .gender-labels {
        position: relative;
        cursor: pointer;
        width: 80px;
    }
    .radio-div {
        float: left;
        margin-top: 3px;
    }
    .fake-rdb {
        vertical-align: middle;
        width: 21px;
        height: 21px;
        border: 1px solid var(--grey);
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: var(--lightergrey);
        cursor: pointer;        
    }
    .gender-span {
        margin-left: 7px;
        height: 100%;
        vertical-align: middle;
    }
    .fake-rdb:after,
    .fake-rdb:before {
        display: none;
        content: "";
        text-align: center;
        margin: auto;
    }
    .gender-labels input:focus ~ .fake-rdb:before {
        display: block;
    }
    .gender-labels input:checked ~ .fake-rdb:after {
        display: block;
    }
    .gender-labels .fake-rdb:after,
    .gender-labels .fake-rdb:before {
        border-radius: 50%;
    }
    .gender-labels .fake-rdb:after {
        width: 11px;
        height: 11px;
        background-color: var(--darker);
    }
    .gender-labels .fake-rdb:before {
        position: absolute;
        top: -1;
        left: -1;
        width: 21px;
        height: 21px;
        border: 2px solid var(--darker);
    }
    .gender-radio {
        position: absolute;
        top: 10px;
        opacity: 0;
        flex: 1;
    }
    .inputs {
        width: 125px;
        height: 24px;
        margin-top: 2px;
        padding-left: 4px;
        border: 1px solid var(--grey);
        border-radius: 5px;
        display: block;
        background-color: var(--lightergrey);
        font-family: 'euclid';
        font-size: 14px;
        font-style: italic;
        color: black;
    }
    .long-inputs {
        width: 100%;
    }
    .old-pass-label, input {
        width: 50%;
    }
    .inputs:focus {
        outline-color: var(--darker);
    }
    .x-icon {
        height: 10px;
        width: 10px;
        margin-right: 2px;
    }
    .first-row-errors,
    .gender-errors,
    .last-row-errors {
        width: 100%;
        display: flex;
    }
    .last-row-errors {
        flex-direction: column;
    }
    .last-row-errors p:nth-child(1) {
        margin-bottom: 0;
    }
    .last-row-errors p:nth-child(2) {
        margin-top: 0;
    }
    .old-pass-errors {
        width: 200%;
    }
    .error-messages {
        font-size: 12px;
        color: var(--darker);
        margin-right: 20px;
        margin-top: 5px;
    }
    .in-mobile {
        display: none;
    }
    .not-in-mobile {
        display: inline-block;
    }
    .save-button-row {
        text-align: center;
    }
    .save-button {
        margin-top: 5px;
        width: 170px;
        height: 38px;
        outline: none;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        font-weight: bold;
        background-color: var(--darker);
        color: white;
    }
    @media screen and (max-width: 530px) {
        .form-container {
            border: none;
            padding: 30px 10%;
            overflow-y: overlay;
            overflow-x: hidden;
        }
        .form-row input {
            width: 100%;
        }
        .male-label {
            margin-right: 10px;
        }
        .form-row:nth-child(1) {
            height: 154px;
        }
        .form-row:nth-child(5) {
            min-height: 154px;
        }
        .my-labels {
            width: 100%;
            padding: 0;
        }
        .last-name-label,
        .confirm-pass-label {
            display: block;
            margin-top: 33px;
        }
        .first-name-has-errors,
        .password-has-errors {
            margin-top: 12px;
        }
        .old-pass-errors {
            width: 100%;
        }
        .in-mobile {
            display: block;
        }
        .not-in-mobile {
            display: none;
        }
        .extra-margin {
            margin-bottom: 5px;
        }
    }
    @media screen and (max-height: 700px) {
        .form-container {
            height: 429px;
            overflow-y: overlay;
        }
    }
</style>
