<template>
    <svg width="24" height="13" viewBox="0 0 24 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="svg-paths" d="M8.00004 7C8.00004 7.26522 8.1054 7.51957 8.29293 7.70711C8.48047 7.89464 8.73482 8 9.00004 8H15C15.2653 8 15.5196 7.89464 15.7071 7.70711C15.8947 7.51957 16 7.26522 16 7C16 6.73478 15.8947 6.48043 15.7071 6.29289C15.5196 6.10536 15.2653 6 15 6H9.00004C8.73482 6 8.48047 6.10536 8.29293 6.29289C8.1054 6.48043 8.00004 6.73478 8.00004 7Z"/>
        <path class="svg-paths" d="M9.00004 11H7.21004C6.1824 11.0274 5.18147 10.6706 4.40291 9.99932C3.62435 9.32802 3.12413 8.39049 3.00004 7.37C2.94843 6.81446 3.0136 6.25427 3.19138 5.72542C3.36915 5.19657 3.6556 4.71076 4.03231 4.2992C4.40902 3.88765 4.86766 3.55945 5.37876 3.33571C5.88986 3.11196 6.44211 2.99761 7.00004 3H9.00004C9.26526 3 9.51961 2.89464 9.70715 2.70711C9.89468 2.51957 10 2.26522 10 2C10 1.73478 9.89468 1.48043 9.70715 1.29289C9.51961 1.10536 9.26526 0.999999 9.00004 0.999999H7.21004C5.72776 0.980191 4.2882 1.49645 3.15634 2.45376C2.02448 3.41106 1.27649 4.74498 1.05004 6.21C0.937485 7.0574 1.0073 7.91906 1.25482 8.73729C1.50234 9.55551 1.92184 10.3114 2.48523 10.9543C3.04861 11.5972 3.74288 12.1124 4.52152 12.4652C5.30016 12.818 6.1452 13.0003 7.00004 13H9.00004C9.26526 13 9.51961 12.8946 9.70715 12.7071C9.89468 12.5196 10 12.2652 10 12C10 11.7348 9.89468 11.4804 9.70715 11.2929C9.51961 11.1054 9.26526 11 9.00004 11ZM23 6.24C22.7764 4.75652 22.0191 3.40563 20.8702 2.44086C19.7213 1.47609 18.2598 0.963755 16.76 0.999999H15.25C14.44 0.999999 14 1.45 14 2C14 2.26522 14.1054 2.51957 14.2929 2.70711C14.4805 2.89464 14.7348 3 15 3H16.79C17.8177 2.97257 18.8186 3.32938 19.5972 4.00068C20.3757 4.67198 20.8759 5.60951 21 6.63C21.0517 7.18554 20.9865 7.74573 20.8087 8.27458C20.6309 8.80343 20.3445 9.28924 19.9678 9.70079C19.5911 10.1123 19.1324 10.4405 18.6213 10.6643C18.1102 10.888 17.558 11.0024 17 11H15C14.7348 11 14.4805 11.1054 14.2929 11.2929C14.1054 11.4804 14 11.7348 14 12C14 12.2652 14.1054 12.5196 14.2929 12.7071C14.4805 12.8946 14.7348 13 15 13H17C17.8562 13.0069 18.7039 12.8305 19.4863 12.4826C20.2686 12.1347 20.9674 11.6234 21.5358 10.9831C22.1041 10.3427 22.5289 9.58816 22.7814 8.77007C23.034 7.95197 23.1085 7.08929 23 6.24Z"/>
    </svg>
</template>

<script type="text/javascript">
    export default {
        name: 'LinkIcon'
    }
</script>