import axios from 'axios';
import { router } from "./routes/router";

export const Api = axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_BASE_URL + "api/",
    headers: {
        "Content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
    }
});

Api.interceptors.request.use (
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

Api.interceptors.response.use(undefined,
    error => {
        if (error.response.status === 401) {
            if(error.response.config.url === 'login') {
                return Promise.reject(error);
            } else {
                router.replace('/login');
            }
        }
        return Promise.reject(error);
    }
);
