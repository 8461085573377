<template>
    <div class="main-container">
        <pages-background></pages-background>
        <nav-bar v-if="navBarIsNeeded"></nav-bar>
        <div class="sub-container">           
            <router-view v-slot="{ Component }">
                <keep-alive>
                    <component :is="Component"/>
                </keep-alive>
            </router-view>
        </div>
    </div>
</template>

<script>
    import NavBar from './components/layout-components/NavBar.vue';
    import PagesBackground from './components/layout-components/PagesBackground.vue';
    import { Themes } from '@/utils/themes.js';

    export default {
        name: 'App',
        
        components: {
            NavBar,
            PagesBackground
        },

        data() {
            return {
                themeObject: null
            }
        },

        created() {
            this.themeObject = Themes.getTheme();
            Themes.changeTheme(this.themeObject);
        },

        computed: {
            navBarIsNeeded() {
                return this.inLoginOrRegister() ? false : true;
            }
        },

        methods: {
            inLoginOrRegister() {
                return this.$route.path === "/login" || this.$route.path === "/register" ? true : false;
            }
        }
    }
</script>

<style>
    .sub-container {
        width: 100%;
        height: 100%;
        min-height: 480px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-y: overlay;
        padding-right: 0;
    }
    @media screen and (max-height: 500px) {
        .sub-container {
            margin-top: 64px;
        }
    }
    @media screen and (max-height: 450px) {
        .sub-container {
            margin-top: 128px;
        }
    }
</style>
