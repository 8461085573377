<template>
    <div class="full-page-cover" @click.self="closePopup()">
        <div class="add-user-form">
            <div class="form-title">
                <button class="form-exit-button" @click="closePopup()">
                    <img src="@/assets/vectors/white-x-icon.svg">
                </button>
                Adding New User
            </div>
            <div v-if="userExists" class="first-page">
                <label class="my-labels email-label-first">
                    Please enter user email:
                    <input class="long-inputs inputs" type="email" name="email" v-model="currentUser.email" v-focus>
                    <p class="error-messages" v-for="error in errors.email" :key="error">
                        <x-icon class="x-icon"></x-icon>
                        {{ error }}
                    </p>
                </label>
                <button @click="addIfExists()" class="save-button">
                    DONE
                </button>
            </div>
            <div v-else class="second-page">
                <div class="form-row first-row">
                    <label class="my-labels first-name-label" tabindex="1">First Name
                        <input placeholder="Johnny" class="inputs" type="text" v-model="currentUser.first_name" v-focus>
                        <p class="error-messages in-mobile" v-for="error in errors.first_name" :key="error">
                            <x-icon class="x-icon"></x-icon>
                            {{ error }}
                        </p>
                    </label>
                    <label class="my-labels last-name-label" :class="{'first-name-has-errors' : errors.first_name}" tabindex="2">Last Name
                        <input placeholder="Appleseed" class="inputs" type="text" v-model="currentUser.last_name">
                    </label>
                    <div class="first-row-errors">
                        <p class="error-messages not-in-mobile" v-for="error in errors.first_name" :key="error">
                            <x-icon class="x-icon"></x-icon>
                            {{ error }}
                        </p>
                        <p class="error-messages last-name-errors" :class="{'less-margin' : errors.last_name}" v-for="error in errors.last_name" :key="error">
                            <x-icon class="x-icon"></x-icon>
                            {{ error }}
                        </p>
                    </div>
                </div>
                <div class="form-row">
                    <label class="my-labels dob-label" tabindex="5">Date of Birth
                        <input class="inputs long-inputs" type="date" v-model="currentUser.date_of_birth">
                        <p class="error-messages" v-for="error in errors.date_of_birth" :key="error">
                            <x-icon class="x-icon"></x-icon>
                            {{ error }}
                        </p>
                    </label>
                </div>
                <div class="form-row">
                    <label for="gender" class="my-labels gender-main-label">Gender</label>
                    <div class="radio-div">
                        <label for="male-rdb" class="my-labels gender-labels male-label" tabindex="3">
                            <input type="radio" name="gender" id="male-rdb" class="gender-radio" value="male" v-model="currentUser.gender">
                            <span class="fake-rdb"></span>
                            <span class="gender-span">Male</span>
                        </label>
                    </div>
                    <div class="radio-div">
                        <label for="female-rdb" class="my-labels gender-labels female-label" tabindex="4">
                            <input type="radio" name="gender" id="female-rdb" class="gender-radio" value="female" v-model="currentUser.gender">
                            <span class="fake-rdb"></span>
                            <span class="gender-span">Female</span>
                        </label>
                    </div>
                    <div class="gender-errors">
                        <p class="error-messages" v-for="error in errors.gender" :key="error">
                            <x-icon class="x-icon"></x-icon>
                            {{ error }}
                        </p>
                    </div>
                </div>
                <div class="form-row">
                    <label class="my-labels new-pass-label" tabindex="2">Email
                        <input class="inputs long-inputs" :class="{'email-disabled': !userExists}" type="text" v-model="currentUser.email" :disabled="!userExists">
                    </label>
                </div>
                <div class="save-button-row">
                    <button @click="saveUser()" :disabled="saving" class="save-button">
                        ADD USER
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    import { Api } from '@/api.js';
    import { Validator } from '../../classes/validator.js';

    export default {
        name: 'AddUserForm',

        data() {
            return {
                authUser: JSON.parse(localStorage.getItem('authUser')),
                currentOrganization: localStorage.getItem('current_organization'),
                userExists: true,
                currentUser: {
                    first_name: null,
                    last_name: null,
                    date_of_birth: null,
                    gender: null,
                    email: null,
                    organization_id: null
                },
                rules: {
                    first_name: "required",
                    date_of_birth: "required",
                    gender: "required",
                    email: "email|required",
                },
                errors: {},
                importing: false,
                saving: false
            }
        },

        methods: {
            addIfExists() {
                const {success, errors} = Validator.validate({ email: this.currentUser.email }, { email: "email|required" });
                
                this.errors = errors;
                if (success) {
                    this.importing = true;
                    Api.post(`organization/${this.currentOrganization}/user/import`, {email: this.currentUser.email})
                        .then(() => {
                            this.$router.go();
                        })
                        .catch((error) => {
                            if (error.response.data.status === "does_not_exist") {
                                this.userExists = false;
                                this.errors.email = null;
                            } else if (error.response.data.status === "already_exist") {
                                this.errors.email = [error.response.data?.mesasge];
                            }
                        })
                        .finally(() => {
                            this.importing = false;
                        })
                }
            },

            saveUser() {
                const {success, errors} = Validator.validate(this.currentUser, this.rules);
                
                this.errors = errors;

                if (success) {
                    this.saving = true;
                    Api.post(`organization/${this.currentOrganization}/user/create`, {
                        first_name: this.currentUser.first_name,
                        last_name: this.currentUser.last_name,
                        date_of_birth: this.currentUser.date_of_birth,
                        gender: this.currentUser.gender,
                        email: this.currentUser.email
                    })
                        .then(() => {
                            this.$router.go();
                        })
                        .catch((error) => {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                            }
                        })
                        .finally(() => {
                            this.saving = false;
                        })
                }
            },

            closePopup() {
                this.$emit('close');
            }
        }
    }
</script>

<style type="text/css" scoped>
    .add-user-form {
        height: 450px;
        width: 100%;
        max-width: 700px;
        margin: 0 8px;
        border-radius: 20px;
        background-color: white;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        cursor: default;
    }
    .first-page {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex: 1;
    }
    .second-page {
        width: 100%;
        flex: 1;
    }
    .form-title {
        position: relative;
        width: 100%;
        height: 45px;
        background-color: var(--darker);
        border-top-left-radius: 19px;
        border-top-right-radius: 19px;
        text-align: center;
        font-size: 20px;
        color: white;
        line-height: 2.2;
    }
    .form-exit-button {
        position: absolute;
        right: 10px;
        top: 10px;
        background-color: var(--darker);
        border: none;
        outline: none;
    }
    .first-page,
    .second-page {
        padding: 30px 10%;
    }
    .email-label-first {
        margin: 70px 20px 40px;
    }
    .email-disabled {
        opacity: 0.4;
    }
    *::-webkit-scrollbar {
        display: none;
    }
    @media screen and (max-width: 530px) {
        .add-user-form {
            border: none;
        }
        .second-page {
            overflow-y: overlay;
            overflow-x: hidden;
        }
        .save-button-row {
            padding: 20px 0;
        }
    }
    @media screen and (max-height: 700px) {
        .add-user-form {
            margin-top: 40px
        }
    }
    @media screen and (max-height: 400px) {
        .add-user-form {
            margin-top: 80px;
        }
    }
    @media screen and (max-height: 350px) {
        .add-user-form {
            margin-top: 150px;
        }
    }
</style>