<template>
    <div class="full-page-cover" @click.self="closePopup()">
        <div class="popup-content">
            <button @click="closePopup()" class="close-theme-picker">
                <x-icon></x-icon>
            </button>
            <p class="theme-picker-title">Pick your theme:</p>
            <div class="color-palette">
                <label for="theme"></label>
                <div class="radio-div-theme">
                    <label for="cotton-candy" class="my-labels theme-labels" tabindex="3">
                        <input type="radio" name="theme" id="cotton-candy" class="theme-radio" @change="showThemeColor()" :value="themes.cottonCandy" v-model="selectedTheme">
                        <span class="fake-rdb-theme fake-rdb-theme-1"></span>
                        <p class="theme-ptag">{{ themes.cottonCandy.name }}</p>
                    </label>
                </div>
                <div class="radio-div-theme">
                    <label for="aqua" class="my-labels theme-labels" tabindex="3">
                        <input type="radio" name="theme" id="aqua" class="theme-radio" @change="showThemeColor()" :value="themes.aqua" v-model="selectedTheme">
                        <span class="fake-rdb-theme fake-rdb-theme-2"></span>
                        <p class="theme-ptag">{{ themes.aqua.name }}</p>
                    </label>
                </div>
                <div class="radio-div-theme">
                    <label for="royal" class="my-labels theme-labels" tabindex="3">
                        <input type="radio" name="theme" id="royal" class="theme-radio" @change="showThemeColor()" :value="themes.royal" v-model="selectedTheme">
                        <span class="fake-rdb-theme fake-rdb-theme-3"></span>
                        <p class="theme-ptag">{{ themes.royal.name }}</p>
                    </label>
                </div>
                <div class="radio-div-theme">
                    <label for="orange-blossom" class="my-labels theme-labels" tabindex="3">
                        <input type="radio" name="theme" id="orange-blossom" class="theme-radio" @change="showThemeColor()" :value="themes.orangeBlossom" v-model="selectedTheme">
                        <span class="fake-rdb-theme fake-rdb-theme-4"></span>
                        <p class="theme-ptag">{{ themes.orangeBlossom.name }}</p>
                    </label>
                </div>
                <div class="radio-div-theme">
                    <label for="forest" class="my-labels theme-labels" tabindex="3">
                        <input type="radio" name="theme" id="forest" class="theme-radio" @change="showThemeColor()" :value="themes.forest" v-model="selectedTheme">
                        <span class="fake-rdb-theme fake-rdb-theme-5"></span>
                        <p class="theme-ptag">{{ themes.forest.name }}</p>
                    </label>
                </div>
                <div class="radio-div-theme">
                    <label for="retro" class="my-labels theme-labels" tabindex="3">
                        <input type="radio" name="theme" id="retro" class="theme-radio" @change="showThemeColor()" :value="themes.retro" v-model="selectedTheme">
                        <span class="fake-rdb-theme fake-rdb-theme-6"></span>
                        <p class="theme-ptag">{{ themes.retro.name }}</p>
                    </label>
                </div>
                <div class="radio-div-theme">
                    <label for="ocean" class="my-labels theme-labels" tabindex="3">
                        <input type="radio" name="theme" id="ocean" class="theme-radio" @change="showThemeColor()" :value="themes.ocean" v-model="selectedTheme">
                        <span class="fake-rdb-theme fake-rdb-theme-7"></span>
                        <p class="theme-ptag">{{ themes.ocean.name }}</p>
                    </label>
                </div>
            </div>
            <div class="popup-last-row">
                <button @click="applyThemeColor()" class="save-button">APPLY</button>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    import { Themes } from '@/utils/themes';

    export default {
        name: "ThemePickerPopup",

        emits: ['close'],

        data() {
            return {
                selectedTheme: Themes.getTheme(),
                currentTheme: Themes.getTheme(),
                themes: Themes.themes
            }
        },

        methods: {
            applyThemeColor() {
                this.currentTheme = this.selectedTheme;
                Themes.setTheme(this.currentTheme);
                this.$emit('close');
            },

            showThemeColor() {
                Themes.changeTheme(this.selectedTheme);
            },

            closePopup() {
                Themes.changeTheme(this.currentTheme);
                this.$emit('close')
            }
        }
    }
</script>

<style type="text/css">
    .theme-picker-title {
        font-size: 20px;
        width: 100%;
        text-align: center;
        color: var(--darkest);
    }
    .color-palette {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .popup-content {
        position: relative;
        background-color: white;
        max-width: 400px;
        border-radius: 20px;
        margin: 0 8px;
        cursor: default;
    }
    .popup-last-row {
        width: 100%;
        text-align: center;
        padding: 20px 0 30px;
    }
    .close-theme-picker {
        position: absolute;
        top: 8px;
        right: 5px;
        background-color: white;
        border: none;
        outline: none;
    }
    .theme-labels {
        position: relative;
        cursor: pointer;
        width: 90px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
    .radio-div-theme {
        float: left;
        margin-top: 3px;
    }
    .fake-rdb-theme {
        vertical-align: middle;
        min-width: 21px;
        min-height: 21px;
        width: 21px;
        height: 21px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;        
    }
    .fake-rdb-theme-1 {
        background-color: #D66D5C;
    }
    .fake-rdb-theme-2 {
        background-color: #1CB3E6;
    }
    .fake-rdb-theme-3 {
        background-color: #7C49BD;
    }
    .fake-rdb-theme-4 {
        background-color: #F18E1A;
    }
    .fake-rdb-theme-5 {
        background-color: #417A48;
    }
    .fake-rdb-theme-6 {
        background-color: #383838;
    }
    .fake-rdb-theme-7 {
        background-color: #495CBD;
    }
    .theme-ptag {
        margin-left: 7px;
        height: 100%;
        text-align: left;
    }
    .fake-rdb-theme:after,
    .fake-rdb-theme:before {
        display: none;
        content: "";
        text-align: center;
        margin: auto;
    }
    .theme-labels input:focus ~ .fake-rdb-theme:before {
        display: block;
    }
    .theme-labels input:checked ~ .fake-rdb-theme:after {
        display: block;
    }
    .theme-labels .fake-rdb-theme:after,
    .theme-labels .fake-rdb-theme:before {
        border-radius: 50%;
    }
    .theme-labels .fake-rdb-theme:after {
        width: 11px;
        height: 11px;
        background-color: white;
    }
    .theme-labels .fake-rdb-theme:before {
        position: absolute;
        top: -1;
        left: -1;
        width: 21px;
        height: 21px;
        border: 2px solid black;
    }
    .theme-radio {
        position: absolute;
        top: 0px;
        opacity: 0;
        flex: 1;
        height: 100%;
        width: 100%;
        cursor: pointer;
    }
    @media screen and (max-width: 500px)
</style>