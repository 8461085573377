<template>
    <div class="full-page-cover" @click.self="closePopup()">
        <div :class="popupType">
            <p>{{ popupMessage }}</p>
            <button v-if="popupType === 'notification'" class="ok-button" @click="closePopup()">OK</button>
            <div v-else>
                <button class="action-buttons cancel-button" @click="closePopup()">CANCEL</button>
                <button class="action-buttons yes-button" @click="completeAction()" :disabled="isDisabled">{{ buttonContent }}</button>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    export default {
        name: 'MessagePopup',

        props: {
            content: {
                type: Object,
                required: true
            },
            actionStatus: {
                type: Boolean,
                required: false
            }
        },

        data() {
            return {
                buttonContent: this.content.type === 'confirm-message' ? 'OK' : 'RESEND',
            }
        },

        computed: {
            popupType() {
                return this.content.type;
            },

            popupMessage() {
                return this.content.message;
            },

            isDisabled() {
                return this.actionStatus;
            }
        },

        methods: {
            closePopup() {
                this.$emit("close-notification");
            },

            completeAction() {
                this.$emit('confirm-action');
            },
        }
    }
</script>

<style type="text/css">
    .full-page-cover {
        position: fixed;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.2);
        z-index: 10;
        cursor: pointer;
        overflow-y: overlay;
    }
    .notification,
    .confirm-message,
    .verification {
        height: 192px;
        width: 407px;
        margin: 0 8px;
        padding: 10px 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        background: white;
        color: var(--darkest);
        font-size: 25px;
        text-align: center;
        border-radius: 20px;
        cursor: default;
    }
    .confirm-message {
        font-size: 20px;
    }
    .verify-div {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ok-button {
        margin: auto;
        display: block;
        width: 116px;
        height: 38px;
        font-weight: 700;
        font-size: 16px;
        background-color: var(--darker);
        border: none;
        outline: none;
        border-radius: 5px;
        color: white;
    }
    .action-buttons {
        width: 100px;
        height: 38px;
        margin: 0 6px;
        font-size: 16px;
        border: none;
        outline: none;
        border-radius: 5px;
        font-weight: bold;
    }
    .yes-button {
        background-color: var(--darker);
        color: white;
    }
    .cancel-button {
        background-color: var(--lightgrey);
        color: var(--darkest);
    }

    .verify {
        width: 150px;
        font-size: 14px;
    }
</style>