<template>
    <div class="card-wrapper">
        <div class="card">
            <slot/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MainCard',
    }
</script>

<style scoped>
    .card-wrapper {
        display:  flex;
        justify-content: center;
        align-items: center;
        height: 542px;
        width: 100%;
        padding: 0 8px;
    }
    .card {
        height: 542px;
        width: 100%;
        max-width: 700px;
        min-width: 300px;
        background-color: white;
        border-radius: 20px;
    }
    @media screen and (max-height: 700px) {
        .card-wrapper,
        .card {
            height: 480px;
        }
    }
</style>
