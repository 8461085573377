<template>
    <svg width="105" height="232" viewBox="0 0 105 232" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.5">
            <path d="M49.9201 126.904C48.0593 132.945 50.8647 140.086 56.3101 143.264C58.5959 144.596 61.2265 145.267 63.4415 146.712C68.2541 149.848 70.1527 155.851 71.7159 161.376C73.5106 167.728 75.31 174.444 73.6664 180.834C72.618 184.91 70.2329 188.514 68.8775 192.5C67.7438 195.838 67.349 199.383 67.7204 202.89C67.9188 204.751 68.3816 206.668 69.6568 208.057C71.4987 210.04 74.4646 210.338 77.1283 210.815C82.7597 211.88 88.0584 214.264 92.5901 217.773C97.1217 221.282 100.757 225.815 103.198 231" stroke="url(#paint0_linear)" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M50.4916 126.904C48.6308 132.945 51.4362 140.086 56.8816 143.264C59.1674 144.596 61.798 145.267 64.013 146.712C68.8256 149.848 70.7242 155.851 72.2874 161.376C74.0821 167.728 75.8815 174.444 74.238 180.834C73.1895 184.91 70.8045 188.514 69.449 192.5C68.3153 195.838 67.9205 199.383 68.2919 202.89C68.4903 204.751 68.9531 206.668 70.2283 208.057C72.0702 210.04 75.0361 210.338 77.6998 210.815C83.3312 211.88 88.6299 214.264 93.1616 217.773C97.6932 221.282 101.329 225.815 103.77 231" stroke="#535461" stroke-width="2" stroke-miterlimit="10"/>
            <path class="balloon-1" d="M58.4496 130.966H45.9436L48.135 123.466H55.6348L58.4496 130.966Z"/>
            <path class="balloon-1" d="M103.77 51.8872C103.77 80.5453 80.5382 122.526 51.8849 122.526C23.2315 122.526 0 80.5405 0 51.8872C2.89999e-07 38.1259 5.46667 24.9282 15.1974 15.1974C24.9282 5.46667 38.1259 0 51.8872 0C65.6486 0 78.8463 5.46667 88.577 15.1974C98.3078 24.9282 103.774 38.1259 103.774 51.8872H103.77Z"/>
            <path d="M55.6348 123.778H48.135L43.7569 121.28H60.0128L55.6348 123.778Z" fill="#535461"/>
            <path opacity="0.1" d="M33.1305 11.5638C33.1305 11.5638 11.2497 23.4417 8.12323 47.1974L33.1305 11.5638Z" fill="white"/>
        </g>
        <defs>
            <linearGradient id="paint0_linear" x1="48.8717" y1="178.964" x2="103.623" y2="178.964" gradientUnits="userSpaceOnUse">
                <stop stop-color="#808080" stop-opacity="0.25"/>
                <stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
                <stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
            </linearGradient>
        </defs>
    </svg>
</template>

<script type="text/javascript">
    export default {
        name: 'BalloonOne'
    }
</script>

<style type="text/css">
    .balloon-1 {
        fill: var(--firstballoon);
    }
</style>