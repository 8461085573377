<template>
    <main-card>
        <div class="static-nav-bar nav-bar">
            <p class="homepage">SBC</p>
        </div>
        <div class="register-container">
            <div class="login-header">
                <div class="register-header">Register</div>
            </div>
            <div class="register-form-container">
                <div class="register-form-row">
                    <label class="my-labels first-name-label" tabindex="1">First Name
                        <input placeholder="Johnny" class="inputs" type="text" v-model="currentUser.first_name" v-focus>
                        <p class="error-messages in-mobile" v-for="error in errors.first_name" :key="error">
                            <x-icon class="x-icon"></x-icon>
                            {{ error }}
                        </p>
                    </label>
                    <label class="my-labels last-name-label" :class="{'first-name-has-errors' : errors.first_name}" tabindex="2">Last Name
                        <input placeholder="Appleseed" class="inputs" type="text" v-model="currentUser.last_name">
                    </label>
                    <div class="first-row-errors">
                        <p class="error-messages not-in-mobile" v-for="error in errors.first_name" :key="error">
                            <x-icon class="x-icon"></x-icon>
                            {{ error }}
                        </p>
                        <p class="error-messages last-name-errors" :class="{'less-margin' : errors.last_name}" v-for="error in errors.last_name" :key="error">
                            <x-icon class="x-icon"></x-icon>
                            {{ error }}
                        </p>
                    </div>
                </div>
                <div class="register-form-row">
                    <label class="my-labels dob-label" tabindex="5">Date of Birth
                        <input class="inputs long-inputs" type="date" v-model="currentUser.date_of_birth">
                        <p class="error-messages" v-for="error in errors.date_of_birth" :key="error">
                            <x-icon class="x-icon"></x-icon>
                            {{ error }}
                        </p>
                    </label>
                </div>
                <div class="register-form-row">
                    <label for="gender" class="my-labels gender-main-label">Gender</label>
                    <div class="radio-div">
                        <label for="male-rdb" class="my-labels gender-labels male-label" tabindex="3">
                            <input type="radio" name="gender" id="male-rdb" class="gender-radio" value="male" v-model="currentUser.gender">
                            <span class="fake-rdb"></span>
                            <span class="gender-span">Male</span>
                        </label>
                    </div>
                    <div class="radio-div">
                        <label for="female-rdb" class="my-labels gender-labels female-label" tabindex="4">
                            <input type="radio" name="gender" id="female-rdb" class="gender-radio" value="female" v-model="currentUser.gender">
                            <span class="fake-rdb"></span>
                            <span class="gender-span">Female</span>
                        </label>
                    </div>
                    <div class="gender-errors">
                        <p class="error-messages" v-for="error in errors.gender" :key="error">
                            <x-icon class="x-icon"></x-icon>
                            {{ error }}
                        </p>
                    </div>
                </div>
                <div class="register-form-row">
                    <label class="my-labels new-pass-label" tabindex="2">Email
                        <input class="inputs long-inputs" type="email" v-model="currentUser.email">
                        <p class="error-messages" v-for="error in errors.email" :key="error">
                            <x-icon class="x-icon"></x-icon>
                            {{ error }}
                        </p>
                    </label>
                </div>
                <div class="register-form-row">
                    <label class="my-labels new-pass-label" tabindex="2">Organization Name
                        <input class="inputs long-inputs" type="text" v-model="currentUser.organization_name">
                    </label>
                    <label class="my-labels confirm-pass-label" :class="{'password-has-errors' : errors.password}" tabindex="2">Organization Website
                        <input class="inputs long-inputs" type="text" v-model="currentUser.organization_website">
                    </label>
                    <div class="last-row-errors">
                        <p class="error-messages" v-for="error in errors.organization_name" :key="error">
                            <x-icon class="x-icon"></x-icon>
                            {{ error }}
                        </p>
                        <p class="error-messages last-name-errors" v-for="error in errors.organization_website" :key="error">
                            <x-icon class="x-icon"></x-icon>
                            {{ error }}
                        </p>
                    </div>
                </div>
                <div class="register-form-row">
                    <label class="my-labels new-pass-label" tabindex="2">Password
                        <input class="inputs long-inputs" type="password" v-model="currentUser.password">
                    </label>
                    <label class="my-labels confirm-pass-label" :class="{'password-has-errors' : errors.password}" tabindex="2">Confirm Password
                        <input class="inputs long-inputs" type="password" v-model="currentUser.password_confirmation">
                    </label>
                    <div class="last-row-errors">
                        <p class="error-messages" v-for="error in errors.password" :key="error">
                            <x-icon class="x-icon"></x-icon>
                            {{ error }}
                        </p>
                        <p class="error-messages last-name-errors" v-for="error in errors.password_confirmation" :key="error">
                            <x-icon class="x-icon"></x-icon>
                            {{ error }}
                        </p>
                    </div>
                </div>
                <div class="save-button-row">
                    <button @click="register()" class="save-button" :disabled="registering">
                        REGISTER
                    </button>
                </div>
                <div class="links-row">
                    <router-link class="login-links" to="/login">Back to Login</router-link>
                </div>
            </div>
        </div>
    </main-card>
    <message-popup
        v-if="registerError.state"
        :content="registerError"
        @close-notification="clearErrors()"
    ></message-popup>
</template>

<script type="text/javascript">
    import { Api } from "@/api.js";
    import axios from "axios";
    import { Validator } from "../../classes/validator.js";

    export default {
        name: 'RegisterPage',

        created() {
            axios.get(process.env.VUE_APP_BASE_URL + "sanctum/csrf-cookie")
                .catch(() => this.error = true);
        },

        data() {
            return {
                registering: false,
                registerError: {
                    state: false,
                    message: "Couldn't register :(",
                    type: "notification"
                },
                currentUser: {
                    first_name: null,
                    last_name: null,
                    date_of_birth: null,
                    gender: null,
                    email: null,
                    organization_name: null,
                    organization_website: null,
                    password: null,
                    password_confirmation: null
                },
                rules: {
                    first_name: 'required',
                    last_name: 'required',
                    date_of_birth: 'required',
                    gender: 'required',
                    email: 'required',
                    organization_name: 'required',
                    organization_website: 'url',
                    password: 'required',
                    password_confirmation: 'required'
                },
                errors: {}
            }
        },

        methods: {
            register() {
                const {success, errors} = Validator.validate(this.currentUser, this.rules);
                this.errors = errors;

                if (success) {
                    this.registering = true;
                    Api.post('register', this.currentUser)
                        .then((response) => {
                            localStorage.setItem('token', response.data.token);
                        }).then(() => {
                            Api.get('user')
                                .then(response => {
                                    localStorage.setItem('authUser', JSON.stringify(response.data));
                                    localStorage.setItem('current_organization', response.data.primary_organization);
                                    this.$router.go("/");
                                })
                        }).catch((error) => {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors
                            } else {
                                this.registerError.state = true;
                            }
                        }).finally(() => this.registering = false)
                }
            },

            clearErrors() {
                this.registerError.state = false;
            }
        }
    }
</script>

<style>

    .register-form-container {
        height: 491px;
        width:  100%;
        padding: 30px 100px;
        overflow-y: overlay;
    }
    .links-row {
        height: 45px;
        text-align: center;
        padding-top: 10px;
    }
    .register-form-row {
        height: 77px;
    }
    @media screen and (max-width: 530px) {
        .register-form-container {
            border: none;
            padding: 30px 10%;
            overflow-y: overlay;
            overflow-x: hidden;
        }
        .register-form-row:nth-child(1),
        .register-form-row:nth-child(5),
        .register-form-row:nth-child(6) {
            height: 154px;
        }
        .register-form-row input {
            width: 100%;
        }
    }
    @media screen and (max-height: 700px) {
        .register-form-container {
            height: 429px;
        }
    }
</style>