<template>
	<svg width="102" height="218" viewBox="0 0 102 218" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.5">
            <path d="M48.9799 123.671L51.1453 141.321C51.4309 143.666 51.6751 146.223 50.4173 148.232C49.2241 150.126 46.9849 151.029 45.2434 152.429C39.5257 157.036 40.0464 165.841 42.0183 172.909C43.9902 179.976 46.8283 187.569 44.087 194.369C42.9536 197.175 40.954 199.539 39.4797 202.174C36.9503 206.677 35.9481 211.879 36.6232 217" stroke="url(#paint0_linear)" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M49.5189 123.671L51.6843 141.321C51.9699 143.666 52.2141 146.223 50.9563 148.232C49.7631 150.126 47.5239 151.029 45.7824 152.429C40.0648 157.036 40.5854 165.841 42.5573 172.909C44.5292 179.976 47.3673 187.569 44.626 194.369C43.4926 197.175 41.493 199.539 40.0187 202.174C37.4893 206.677 36.4871 211.879 37.1622 217" stroke="#535461" stroke-width="2" stroke-miterlimit="10"/>
            <path class="balloon-3" d="M57.0242 127.766H44.8241L46.9619 120.446H54.2782L57.0242 127.766Z"/>
            <path class="balloon-3" d="M101.235 50.6177C101.235 78.5746 78.5723 119.529 50.62 119.529C22.6678 119.529 0 78.5746 0 50.6177C0 37.1931 5.33292 24.3183 14.8256 14.8256C24.3183 5.33293 37.1931 0 50.6177 0C64.0424 0 76.9172 5.33293 86.4099 14.8256C95.9025 24.3183 101.235 37.1931 101.235 50.6177Z"/>
            <path d="M54.2782 120.75H46.9619L42.691 118.312H58.5492L54.2782 120.75Z" fill="#535461"/>
            <path opacity="0.1" d="M32.3246 11.2809C32.3246 11.2809 10.9791 22.8682 7.92908 46.0427L32.3246 11.2809Z" fill="white"/>
        </g>
        <defs>
            <linearGradient id="paint0_linear" x1="11186.5" y1="55706.9" x2="11703.1" y2="55706.9" gradientUnits="userSpaceOnUse">
                <stop stop-color="#808080" stop-opacity="0.25"/>
                <stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
                <stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
            </linearGradient>
        </defs>
    </svg>
</template>

<script type="text/javascript">
	export default {
		name: 'BalloonThree'
	}
</script>

<style type="text/css">
    .balloon-3 {
        fill: var(--thirdballoon);
    }
</style>